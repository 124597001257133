import React, { useState, useEffect } from 'react';
import '../../../../styles/_home-detail.scss';
import { verifyTicket } from 'redux/verify-ticket/verifyTicketSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import PropTypes from 'prop-types';
import { IoMdClose } from 'react-icons/io';
import { resetPayTicket } from 'redux/verify-ticket/payTicketSlice';
import { resetTicketCanceled } from 'redux/verify-ticket/cancelTicketSlice';
import ticketwhite from '../../../../assets/images/mathys/svg/ticketwhite.svg';
import barCodeImage from '../../../../assets/images/mathys/svg/barcode.svg';

const VerifyTicket = ({ isMobile }) => {
  const dispatch = useDispatch();
  const iconStyle = {
    color: '#777777',
    fontSize: '32px',
  };
  const { t } = useTranslation();
  const { agentId } = useSelector((store) => store.login);
  const [disabled, setDisabled] = useState(true);
  const [scanBarCode, setScanBarCode] = useState(false);
  const { ticketPaid } = useSelector((store) => store.ticketPaid);
  const { ticketCanceled } = useSelector((store) => store.ticketCanceled);
  const { msg135, msg18, msg71 } = translationKeys;

  const handleChange = () => {
    const ticketNumber = document.getElementById('ticketNumber').value;
    if (ticketNumber !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ticketNumber = e.target.elements.ticketNumber.value;
    localStorage.setItem('ticketNumber', ticketNumber);
    localStorage.setItem('payRefCode', `%5B%22${ticketNumber}%22%5D`);
    // const digitRegex = /^\d{12}$/;
    // // Check if the ticketNumber matches the regular expression
    // if (!digitRegex.test(ticketNumber)) {
    //   setMsg(true);
    //   setAlertMessage('Veuillez entrer un numéro valide.');
    //   return;
    // }
    dispatch(verifyTicket({
      refCode: ticketNumber,
      agentId,
    }));
  };

  // const barCodeScan = (detectedBarCode) => {
  // };

  useEffect(() => {
    if (ticketPaid || ticketCanceled) {
      setDisabled(true);
      document.getElementById('ticket-verification-form').reset();
      if (ticketPaid) {
        dispatch(resetPayTicket());
      }

      if (ticketCanceled) {
        dispatch(resetTicketCanceled());
      }
    }
  }, [ticketPaid, ticketCanceled, dispatch]);

  return (
    <div className="details verifier-ticket">
      {!isMobile && (
      <div className="header">
        <img src={ticketwhite} alt="" />
        <span>{t(msg135)}</span>
      </div>
      )}
      <div className="content verify-content">
        <form action="" onSubmit={handleSubmit} className="verify-form" id="ticket-verification-form">
          <div className="content-grid">
            <div className="input-wrap">
              <label htmlFor="ticketNumber" className="form-label verify-label">
                {t(msg71)}
              </label>
              <input
                type="text"
                className="form-control verify-input"
                id="ticketNumber"
                required
                disabled={scanBarCode}
                placeholder="Ex: 300614792988"
                onChange={handleChange}
              />
            </div>
            {isMobile ? (
              <div className="actions reverse no-padding">
                <button
                  type="submit"
                  className="btn btn-secondary verify-btn"
                  disabled={disabled}
                >
                  <span>{t(msg18)}</span>
                </button>
                <button type="button" className="btn btn-icon" onClick={() => { setScanBarCode(true); }}>
                  <span className="icon" style={{ maskImage: `url(${barCodeImage})` }} />
                </button>
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-secondary verify-btn"
                disabled={disabled}
              >
                <span>{t(msg18)}</span>
              </button>
            )}
          </div>
        </form>
      </div>
      {scanBarCode && (
      <div className="scanner-container">
        <div className="actions reverse no-padding">
          <h4 className="title">Scanner</h4>
          <button className="btn btnClose sm" type="button" onClick={() => { setScanBarCode(false); }}><IoMdClose style={iconStyle} /></button>
        </div>
      </div>
      )}
    </div>
  );
};

VerifyTicket.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default VerifyTicket;
