/* eslint max-len: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { getMeilleursComp } from 'redux/meilleursCompetition/meilleursCompSlice';
import { getTournamentMatches } from 'redux/tournament-matches/tournamentMatchesSlice';
import { getCategoryTournaments } from 'redux/category-tournaments/categoryTournamentsSlice';
import { getAllSports, selectSport } from 'redux/all-sports/allSportsSLice';
import '../../../styles/_dashboard.scss';
import { getCategories } from 'redux/categories/categoriesSlice';
import translationKeys from 'translations/translationKeys';
import { getTomatchesMkts } from 'redux/meilleursParis/topmatchesMktsSlice';
import PropTypes from 'prop-types';
import { resetHeader, setTournament } from 'redux/nav-header/navHeader';
import fire from '../../../assets/images/mathys/svg/fire.svg';
import circle from '../../../assets/images/mathys/svg/play-circle.svg';
import camera from '../../../assets/images/mathys/svg/camera-reels.svg';
import arrowRight from '../../../assets/images/mathys/svg/arrow-right.svg';
import sportImage from '../../../assets/images/mathys/png/sport.png';
import homeImage from '../../../assets/images/mathys/svg/house.svg';
import defaultImage from '../../../assets/images/mathys/png/football.png';
import crossImage from '../../../assets/images/mathys/svg/x-lg.svg';
import backIcon from '../../../assets/images/mathys/svg/chevron-left.svg';
import forwardIcon from '../../../assets/images/mathys/svg/chevron-right.svg';
import jackpotImage from '../../../assets/images/mathys/png/jackpot.png';
import burgerImage from '../../../assets/images/mathys/svg/burger.svg';
import baseUrls from '../../../config';

const LeftSideBar = ({ isMobile, openMobileBetSlip }) => {
  const { pathname } = useLocation(); // Stay on the current path when clicked on 'Meilleures Compétitions'  since 'Meilleures Compétitions' only opens a menu
  const navigate = useNavigate();
  const { t } = useTranslation();
  const imgUrl = baseUrls.BASE_URL_IMG;
  const dispatch = useDispatch();
  const [className, setClassName] = useState('sub-menu hide-sub-menu');
  const [tClassName, setTClassName] = useState('tournament');
  const [openItemId, setOpenItemId] = useState(null);
  const [menuToggle, setMenuToggle] = useState(false);
  const { allSports } = useSelector((store) => store.allSports);
  const { meilleursComp, meilleursCompSuccess } = useSelector((store) => store.meilleursComp);
  const { categories } = useSelector((store) => store.categories);
  const { tournaments } = useSelector((store) => store.tournaments);
  const { allTopMatches } = useSelector((store) => store.allTopMatches);
  const { cleanedMarkets } = useSelector((store) => store.topMatchesMkts);
  const {
    bets,
    multiple, simple, multipleSimple,
    numIntegralTickets,
  } = useSelector((store) => store.betSlip);
  const { homeList } = useSelector((store) => store.homeList);
  const { tournament, details } = useSelector((store) => store.navigationHeader);
  let text = JSON.parse(localStorage.getItem('text')); // Set below
  let buttonId = JSON.parse(localStorage.getItem('buttonId')); // Set below

  const {
    msg11, msg10, msg9, msg12, msg13, msg153,
  } = translationKeys;

  const list = [

    {
      id: uuidv4(), img: fire, path: 'meilleurs-paris', text: msg9,
    },
    {
      id: uuidv4(), img: circle, path: 'today-matches', text: msg10,
    },
    // {
    //   id: uuidv4(), img: camera, text: 'Direct', path: 'direct', className: 'normal', func: showSideBar,
    // },
    {
      id: uuidv4(), img: camera, text: msg11, path: pathname, className: 'normal',
    },

  ];

  const links = [
    {
      id: uuidv4(), path: 'home', text: msg12, img: homeImage, className: 'btn btn-secondary',
    },
    {
      id: uuidv4(), path: 'meilleurs-paris', text: msg13, img: sportImage, className: 'btn btn-secondary show-sports-memu',
    },
    {
      id: uuidv4(), path: '#', text: 'Jackpot', img: jackpotImage, className: 'btn btn-secondary disabled',
    },
  ];

  const activeSport = (path) => (pathname.includes('match') && path === 'meilleurs-paris');

  const activeDashBoard = (path) => (homeList.some((homeLink) => pathname.includes(homeLink.code)) && path === 'home');

  const hideSideBar = () => {
    setClassName('sub-menu hide-sub-menu');
    setTClassName('tournament');
    setMenuToggle(false);
  };

  const toggleAcc = (itemId) => {
    setOpenItemId((prevItemId) => (prevItemId === itemId ? null : itemId));
  };

  const showSideBar = () => {
    setClassName('sub-menu');
    setTClassName('tournament');
  };

  const toggleTournamentsBar = (toggle) => {
    if (toggle) {
      setTClassName('tournament expand');
      setClassName('sub-menu');
    } else {
      showSideBar();
    }
  };
  const clearAllMenus = () => {
    setClassName('sub-menu hide-sub-menu');
    setTClassName('tournament');
  };

  useEffect(() => {
    if (pathname.includes('match') || pathname.includes('paris')) {
      dispatch(getAllSports());

      if (pathname.includes('paris')) {
        buttonId = msg9;
        text = msg9;
      }

      if (!isMobile) {
        showSideBar();
      }
    } else {
      if (pathname.includes('home') && isMobile) {
        if (list.some((navigation) => navigation.text === tournament?.name)) {
          showSideBar();
        } else if (details?.name || (tournament?.name)) {
          toggleTournamentsBar(true);
        }

        dispatch(resetHeader());
      }

      if (!isMobile) {
        hideSideBar();
      }
    }
    if (Object.keys(cleanedMarkets).length < 1) {
      dispatch(getTomatchesMkts());
    }
  }, [dispatch, pathname, isMobile, cleanedMarkets]);

  useEffect(() => {
    if (allTopMatches.length > 0) {
      localStorage.setItem('topSportSelected', allTopMatches[0].id);
    }
  }, [allTopMatches]);

  useEffect(() => {
    if (!meilleursCompSuccess) {
      dispatch(getMeilleursComp());
    }
  }, [meilleursCompSuccess, dispatch]);

  return (
    <div className="sidebar">
      {isMobile && (
        <div className="bottom-selections">
          <button className="menu-toggler" type="button" onClick={() => { setMenuToggle(!menuToggle); }}>
            <span style={{ maskImage: `url(${menuToggle ? crossImage : burgerImage})` }} className="icon" />
            <span className="title">Menu</span>
          </button>
          <button className="betslip-toggler" type="button" onClick={openMobileBetSlip}>
            <span className="number-selection title">{(simple || multiple || multipleSimple) ? bets.length : numIntegralTickets}</span>
            <span className="title">{t(msg153)}</span>
          </button>
        </div>
      )}
      <div className={`group-btn ${menuToggle ? 'up' : ''}`}>
        {links?.length > 0 && links.map((link) => link && (!isMobile || link.path !== 'home') && (
        <NavLink
          to={link.path}
          key={link.id}
          className={({ isActive }) => (isActive || activeSport(link.path) || activeDashBoard(link.path) ? `${link.className} selected` : link.className)}
          onClick={() => {
            localStorage.setItem('activeSideMenu', JSON.stringify(link.text)); // Set active button to Top matches
            if (link.path.includes('paris') && isMobile) {
              showSideBar();
            }
          }}
        >
          <img src={link.img} alt="" />
          <span className="title">{t(link.text)}</span>
        </NavLink>
        ))}
      </div>
      <div className={className} id="sub-menu">
        <div className="content-sub-menu">
          <div className="content-sport">
            <ul className="tab-list">
              {isMobile && (
              <li key="sport-title">
                <NavLink
                  className="title"
                  to="home"
                  onClick={() => {
                    if (isMobile) {
                      hideSideBar();
                    }
                  }}
                >
                  <button className="btn btn-nav" type="button">
                    <img src={backIcon} alt="menu" />
                  </button>
                  <span>SPORTS</span>
                </NavLink>
              </li>
              )}
              {list.map((item) => (
                <li key={item.id}>
                  <button
                    type="button"
                    className={(buttonId === item.text && !isMobile) ? 'btn normal selected' : 'btn normal'} // Set active button
                    onClick={() => {
                      if (item.path === pathname) {
                        dispatch(getMeilleursComp());
                        toggleTournamentsBar(true);
                      } else {
                        dispatch(setTournament({ name: item.text, redirect: 'home' }));
                        navigate(item.path);
                        if (isMobile) {
                          hideSideBar();
                        }
                      }

                      localStorage.setItem('text', JSON.stringify(item.text)); // Used to determine which menu items to display on third menu
                      localStorage.setItem('buttonId', JSON.stringify(item.text)); // Set active button
                    }}
                  >
                    <img src={item.img} alt="" />
                    <span>{t(item.text)}</span>
                    {isMobile && <img src={forwardIcon} alt="navigate" className="btn-nav" />}
                  </button>
                </li>
              ))}

              {allSports?.length > 0 ? allSports.map((item) => item && (
                <li key={item.id}>
                  <button
                    type="button"
                    className={(buttonId === item.name && !isMobile) ? 'btn normal selected' : 'btn normal'}
                    onClick={() => {
                      dispatch(selectSport(item.id));
                      dispatch(getCategories(item.id));
                      toggleTournamentsBar(true);
                      localStorage.setItem('text', JSON.stringify(item.name)); // Used to determine which menu items to display on third menu
                      localStorage.setItem('buttonId', JSON.stringify(item.name)); // Set active button
                    }}
                  >
                    <img
                      src={item.icon ? imgUrl + item.icon : defaultImage}
                      alt=""
                    />
                    <span>{item.name}</span>
                    {isMobile && <img src={forwardIcon} alt="navigate" className="btn-nav" />}
                  </button>
                </li>
              )) : null}
            </ul>
          </div>
          <div className={tClassName}>
            <div
              className="navs top-event title"
              onClick={() => {
                if (isMobile) {
                  toggleTournamentsBar(false);
                }
              }}
            >
              {isMobile && (
              <button className="btn btn-nav" type="button">
                <img src={backIcon} alt="menu" />
              </button>
              )}
              <span>
                { text === 'Meilleures Compétitions' ? t(msg11) : text }
              </span>
              {!isMobile && (
              <div className="close-tab">
                <button
                  type="button"
                  id="major-tournaments"
                  className="btn"
                  onClick={() => {
                    showSideBar();
                  }}
                >
                  <img src={crossImage} alt="x" className="icon" />
                </button>
              </div>
              )}
            </div>
            <ul className="list-unstyled">
              {(text === 'Meilleures Compétitions') && (meilleursComp?.length > 0) ? (
                meilleursComp.map((m) => (
                  <li key={m.id}>
                    <NavLink
                      className="navs "
                      to="/dashboard/matches"
                      onClick={() => {
                        dispatch(getTournamentMatches({ tournamentId: m.id }));
                        localStorage.setItem('tournamentId', JSON.stringify(m.id)); // Make tournamentId available for use at UniversalComponent.jsx
                        localStorage.setItem('marketId', '1');
                        if (!isMobile) {
                          showSideBar();
                        } else {
                          clearAllMenus();
                        }

                        dispatch(setTournament({ name: m.name, redirect: 'home' }));
                      }}
                    >
                      <span>{m.name}</span>
                      <img src={isMobile ? forwardIcon : arrowRight} alt="" />
                    </NavLink>
                  </li>
                ))
              ) : null}
              {(text !== 'Meilleures Compétitions') && (
                categories?.length > 0 ? categories.map((item) => item && (
                  <div key={item.id} className={`cat-cont ${openItemId === item.id ? 'active' : ''}`}>
                    <button
                      type="button"
                      className="navs tnmt-btn"
                      onClick={() => {
                        dispatch(getCategoryTournaments(item.id));
                        toggleAcc(item.id);
                      }}
                    >
                      <span>{item.name}</span>
                      {isMobile && (<img src={forwardIcon} alt="" className={`arrow-icon ${openItemId !== item.id ? '' : 'rotate'}`} />)}
                    </button>
                      { tournaments.categoryId === item.id && (
                        <div className={`openAcc${openItemId === item.id ? '' : ' closed'}`}>
                          { tournaments?.tournaments?.length > 0 ? tournaments.tournaments.map((t) => t && (
                          <NavLink
                            className="navs sub"
                            key={t.id}
                            to="/dashboard/matches"
                            onClick={() => {
                            // dispatch(getTournamentId(t.id));
                              dispatch(getTournamentMatches({ tournamentId: t.id }));
                              localStorage.setItem('marketId', '1');
                              if (!isMobile) {
                                showSideBar();
                              } else {
                                clearAllMenus();
                              }
                              // localStorage.setItem('marketSelected', JSON.stringify('1x2'));
                              localStorage.setItem('tournamentId', JSON.stringify(t.id)); // To be used at UniversalComponent.jsx to refetch data when page is refreshed
                              dispatch(setTournament({ name: `${item.name} - ${t.name}`, redirect: 'home' }));
                            }}
                          >
                            <span>{t.name}</span>
                            <img src={isMobile ? forwardIcon : arrowRight} alt="" />
                          </NavLink>
                          )) : null}
                        </div>
                      )}
                  </div>
                )) : null
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

LeftSideBar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  openMobileBetSlip: PropTypes.func.isRequired,
};

export default LeftSideBar;
