import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import LeftSideBar from './LeftSideBar';
import BetSlip from './BetSlip/BetSlip';
import '../../../styles/_dashboard.scss';

const Body = ({ isMobile }) => {
  const [toggleBetSlip, setToggleBetSlip] = useState(false);

  const closeBetSlip = () => setToggleBetSlip(false);
  const openBetSlip = () => setToggleBetSlip(true);

  return (
    <div className="body">
      <LeftSideBar isMobile={isMobile} openMobileBetSlip={openBetSlip} />
      <Outlet />
      <BetSlip isMobile={isMobile} toggleBetSlip={toggleBetSlip} closeBetSlip={closeBetSlip} />
    </div>
  );
};

Body.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Body;
