import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Loading from 'components/common/Loading';
import Error from 'components/common/Error';
import {
  addToBetSlip,
  deleteFromBetSlip,
  calculateOdds,
  updateStake,
} from 'redux/configs/betConfigsSlice';
import {
  selectFromDetail,
  unSelectFromDetail,
  getEventMarkets,
} from 'redux/Event-markets/eventMarketSlice';
import '../../../../styles/_match-details.scss';
import { useSelector, useDispatch } from 'react-redux';
import formatDateTime from 'components/helpers/formatDateTime';
import PropTypes from 'prop-types';
import baseUrls from '../../../../config';
import backIcon from '../../../../assets/images/mathys/svg/chevron-left.svg';

const MatchDetails = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    stake, minStake, bets,
  } = useSelector((store) => store.betSlip);
  const betUniqueIds = bets.map((bet) => bet.uniqueId);
  const {
    eventMarkets, isLoading, error, successGetEventMarkets,
  } = useSelector((store) => store.eventMarkets);
  const [date, setDate] = useState(null);
  const { details } = useSelector((store) => store.navigationHeader);
  const [eventDetails, setEventDetails] = useState(JSON.parse(localStorage.getItem('eventDetails')));
  const redirectUrl = details?.redirect ?? '/dashboard/matches';

  const imgUrl = baseUrls.BASE_URL_IMG;
  const navigate = useNavigate();
  const handleUnSelect = (uniqueId) => {
    dispatch(unSelectFromDetail(uniqueId));
  };
  const handleSelect = (uniqueId) => {
    dispatch(selectFromDetail(uniqueId));
  };
  const handleButtonClick = (match, market, uniqueId) => {
    const existingItem = betUniqueIds.includes(uniqueId);
    if (existingItem) {
      handleUnSelect(uniqueId);
      dispatch(deleteFromBetSlip(uniqueId));
    } else {
      dispatch(addToBetSlip({
        match,
        odds: market.odds,
        team: market.name,
        market: market.market.name,
        matchId: match.id,
        id: market.id,
        uniqueId: market.uniqueId,
        inBetSlip: market.inBetSlip,
        matchNumber: match.number,
        pick: `${match.number}*${market.market.id}*${market.outcome_id}`,
      }));
    }
  };
  // When page is refreshed and path is /dashboard/match-details, fetch event details.
  useEffect(() => {
    const eventInfo = JSON.parse(localStorage.getItem('eventDetails'));
    if (eventInfo) {
      if (eventDetails === null) {
        setEventDetails(eventInfo);
      } else {
        setDate(formatDateTime(eventDetails.time));
      }

      if (!successGetEventMarkets) {
        dispatch(getEventMarkets(eventInfo.id));
      }
    } else {
      navigate(-1);
    }
  }, [eventDetails, successGetEventMarkets, dispatch]);
  const btns = [
    {
      id: 1,
      text: 'Résultat',
    },
    {
      id: 2,
      text: 'Total de buts',
    },
    {
      id: 3,
      text: 'But d\'équipe',
    },
    {
      id: 4,
      text: 'Buteur',
    },
    {
      id: 5,
      text: 'Différence de but',
    },
    {
      id: 6,
      text: 'Score',
    },
  ];
  return (
    <div className="list-dashboard">
      <div className="details-sport">
        {(eventMarkets && eventMarkets.length > 0 && eventDetails) && (
        <div className="header">
          {!isMobile && (
          <button type="button" className="btn btn-link btn-back" onClick={() => { navigate(redirectUrl); }}>
            <img src={backIcon} alt="" />
          </button>
          )}
          <div className="ctn-center gap">
            <span className="tournament">{eventDetails.info.tournament.name}</span>
            {!isMobile && (
            <div className="match">
              <img src={`${imgUrl}${eventDetails.info.sport.icon}`} alt="" />
              <h4>
                {eventDetails.info.teamhome.name}
                {' VS '}
                {eventDetails.info.teamaway.name}
              </h4>
            </div>
            )}
            <div className="time">
              {date}
            </div>
            {eventDetails?.status && (
            <span>{eventDetails.status && eventDetails.status.status}</span>
            )}
            <span className="count">{eventDetails.number}</span>
          </div>
        </div>
        )}
        <div className="content">
          {!isMobile && (
          <div className="menu">
            {btns?.length > 0 && btns.map((item) => item && (
            <button
              type="button"
              className="btn btn-outline-secondary"
              key={item.id}
            >
              {t(item.text)}
            </button>
            ))}
          </div>
          )}

          <div className="ctn-odd">
            {isLoading ? <Loading /> : null}
            {error && !isLoading ? <Error text={t(error)} /> : null}
            {(!isLoading && !error && eventMarkets?.length > 0) ? eventMarkets.map((e) => {
              const { markets } = e;
              return markets?.length > 0 ? markets.map((m) => {
                const { outcomes } = m;
                return (
                  <div key={`${m.group_id}-${m.id}-${m.specifier}`} className="market">
                    <span className="m-title">{m.name}</span>

                    <div className="group-odd">
                      {outcomes?.length > 0 ? outcomes.map((item) => {
                        const itemInBetSlip = betUniqueIds.includes(item.uniqueId);
                        return (
                          <button
                            key={`${m.id}-${item.matchId}-${item.id}`}
                            type="button"
                            className={itemInBetSlip ? 'btn btn-odd marked-btn' : 'btn btn-odd'}
                            onClick={() => {
                              handleSelect(item.uniqueId);
                              handleButtonClick(eventDetails, item, item.uniqueId);
                              dispatch(updateStake(stake === 0 ? minStake : stake));
                              dispatch(calculateOdds());
                            }}
                          >
                            <span>{item.name}</span>
                            <span>{item.odds}</span>
                          </button>
                        );
                      }) : null}
                    </div>

                  </div>
                );
              }) : null;
            }) : null}
          </div>

        </div>
      </div>
    </div>
  );
};

MatchDetails.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default MatchDetails;
