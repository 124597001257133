/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authParams, getBase64Hash, parseParams } from 'components/helpers/base64AuthParams';
import { getDeviceImei } from 'components/helpers/printTicketData';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import axios from 'axios';
import ERROR_MESSAGES from 'utils/error-code';
import { getUserInfo, setUserInfo } from 'components/helpers/checkToken';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29, msg23 } = translationKeys;
const api = '/agent-servicev2/ser_agent_auth';
export const login = createAsyncThunk(
  'session/login',
  async (body, thunkAPI) => {
    const imei = getDeviceImei();
    const lang = getLanguage();
    const updatedBody = {
      ...body,
      lang,
      ime: (imei && (imei !== undefined || imei !== null)) ? imei : 'OLD_VERSION',
    };
    const stringParams = parseParams(updatedBody);
    const url = `/${apiVersion2}${api}?${stringParams}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${api}${stringParams}`)}`,
        },
      };
      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const expireAt = sessionStorage.getItem('expireAt');
const notExpired = (expireAt && (expireAt > Date.now()));

const userInfo = notExpired && getUserInfo();
const token = notExpired && (sessionStorage.getItem('token') ?? '');
const hasSession = notExpired && token !== '';

const initialState = {
  isLoading: false,
  agentId: userInfo?.agentId,
  agentPwd: userInfo?.agentPwd,
  agentPhone: userInfo?.agentPhone,
  token,
  success: hasSession,
  isLoggedIn: hasSession,
  loginMessage: '',
  resetPinMessage: '',
  error: '',
};

const loginSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    logout: (state) => {
      sessionStorage.removeItem('userInfo');
      sessionStorage.removeItem('refreshToken');
      sessionStorage.removeItem('expireAt');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('agentId');
      localStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        success: false,
      };
    },
    hideResetPinModal: (state) => ({
      ...state,
      loginMessage: '',
      resetPinMessage: '',
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => ({
        ...state,
        isLoading: true,
        isLoggedIn: false,
        success: false,
        loginMessage: '',
        message: '',
      }))
      .addCase(login.fulfilled, (state, { payload, meta }) => {
        const { outData, erc, data } = payload;
        const { arg } = meta;
        let { agentId, agentPhone, agentPwd } = state;
        let success = false;
        let loginMessage = '';
        let resetPinMessage = '';
        let isLoggedIn = false;
        let token = '';
        const error = '';

        if (erc === '1') {
          if (!outData.access_token) {
            loginMessage = msg23;
          } else {
            agentId = data.id;
            agentPwd = arg.str_pin;
            agentPhone = data.phone;
            setUserInfo({ agentId, agentPwd, agentPhone });
            sessionStorage.setItem('expireAt', outData.expire_at);
            sessionStorage.setItem('token', JSON.stringify(outData.access_token));
            sessionStorage.setItem('refreshToken', JSON.stringify(outData.refresh_token));
            isLoggedIn = true;
            token = outData.access_token;
            success = true;
            isLoggedIn = true;
          }
        } else if (erc === '171') {
          resetPinMessage = ERROR_MESSAGES['171'];
          setUserInfo({
            phone: payload.phn,
            agentId: arg.str_mat,
          }, true);
        } else {
          loginMessage = ERROR_MESSAGES[erc] ?? msg29;
        }

        if (payload[0]) {
          const errorCode = payload[0]?.erc;

          if (errorCode) {
            loginMessage = ERROR_MESSAGES[errorCode] ?? msg29;
          } else {
            loginMessage = msg29;
          }
        }

        return {
          ...state,
          agentId,
          agentPwd,
          agentPhone,
          success,
          loginMessage,
          resetPinMessage,
          error,
          isLoggedIn,
          token,
          isLoading: false,
        };
      })

      .addCase(login.rejected, (state) => ({
        ...state,
        isLoading: false,
        isLoggedIn: false,
        success: false,
        loginMessage: msg29,
      }));
  },
});

export const { logout, getPassWord, hideResetPinModal } = loginSlice.actions;

export default loginSlice.reducer;
