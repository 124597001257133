import translationKeys from 'translations/translationKeys';

const {
  msg24, msg25, msg26, msg27, msg28, msg29, msg30, msg31, msg32, msg38,
  msg39, msg89, msg91, msg113, msg114, msg115, msg138,
  msg116, msg117, msg118, msg119, msg120, msg128, msg129,
  msg131, msg148, msg150, msg155, msg179, msg180, msg213,
} = translationKeys;

const ERROR_MESSAGES = {
  'Deposit failed': msg155,
  0: msg29,
  '01': '',
  '02': msg117,
  '03': msg118,
  '04': msg116,
  '05': msg115,
  '06': msg114,
  '07': msg113,
  '08': msg29,
  '023': msg150,
  6: msg27,
  9: msg29,
  10: msg148,
  13: msg25,
  25: msg30,
  37: msg179,
  38: msg28,
  45: msg119,
  90: msg91,
  111: msg25,
  109: msg89,
  116: msg120,
  123: msg180,
  151: msg38,
  157: msg129,
  158: msg128,
  160: msg138,
  164: msg26,
  168: msg131,
  171: msg24,
  176: msg32,
  177: msg31,
  191: msg213,
  305: msg39,
};

export default ERROR_MESSAGES;
