/* eslint  max-len: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { hideModal } from 'redux/place-bet/resumeBetsSlice';
import translationKeys from 'translations/translationKeys';
import {
  clearBetSlip,
  calculateGains,
  calculateOdds,
  applyBonus,
} from 'redux/configs/betConfigsSlice';
import { resetSuccess, placeBet } from 'redux/place-bet/placeBetsSlice';
import { hideVerifyModal } from 'redux/verify-ticket/verifyTicketSlice';
import { directRecharge, hideRechargeConfirmBox } from 'redux/deposit/depositSlice';
import { bonRecharge, hideBonRechargeConfirmBox } from 'redux/deposit/bonDepositSlice';
import {
  resetAccountCreationMessage, resetAccountCreation, resetAccountValidation, resetResendActivationCode,
} from 'redux/customer-account-management/customerAccountManagementSlice';
import { hideshowBalanceBox } from 'redux/solde/getBalanceSlice';
import { logout } from 'redux/login/loginSlice';
import { printTicket, refreshPrinting } from 'redux/print/printTicketSlice';
import {
  cancelTicket, resetTicketCanceled, hideCancelConfirmBox, showCancelConfirmBox,
} from 'redux/verify-ticket/cancelTicketSlice';
import { hideWithdrawWithCodeBox, withdrawWithCode } from 'redux/withdrawal/withdrawWithCodeSlice';
import {
  payTicket, showPayConfirmBox, resetPayTicket, hidePayConfirmBox,
} from 'redux/verify-ticket/payTicketSlice';
import { hideCheckWithdrawConfirmBox } from 'redux/withdrawal/checkWithdrawalSlice';
import { hideHistModal } from 'redux/history/historySlice';
import { getDeviceImei, printTicketData } from 'components/helpers/printTicketData';
import { showResetPinForm, hideResetPinForm, hideResetMsg } from 'redux/reset-pin/resetPinSlice';
import { IoMdClose } from 'react-icons/io';
import tokenText from 'components/helpers/tokenText';
import {
  cancelObj, payObj, checkWithdrawObj, userObj,
} from './requestBody';
import {
  VerifyTicketContent, ResumeBetContent, MessageContent, SoldeContent,
} from './ModalContents';
import '../../styles/_modal.scss';
import logo from '../../assets/images/mathys/png/logo.png';

const Modal = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userAgent } = window.navigator;
  const couponAmt = JSON.parse(localStorage.getItem('couponAmt'));
  const refCode = localStorage.getItem('payRefCode');
  const ticketNumber = localStorage.getItem('ticketNumber');
  const [className, setClassName] = useState('modalContainer');
  const {
    betType, stake, pick, minStake, multipleSimple, simpleTickets, retail, agentBonus,
  } = useSelector((store) => store.betSlip);
  const {
    resumeBets, previewSuccess, previewMsg, resumeBetLoading,
  } = useSelector((store) => store.resumeBets);
  const { balance } = useSelector((store) => store.balance);
  const { agentId, agentPwd, agentPhone } = useSelector((store) => store.login);
  const {
    isCancelTicketLoading,
    ticketCanceled,
    cancelTicketMsg,
    failedCancelTicket,
    ticketNotRegistered,
    ticketAlreadyCanceled,
    showCancelConfirm,
  } = useSelector((store) => store.ticketCanceled);
  const {
    ticketPaid, isPaidLoading, failedPayTicket, payTicketMsg, paidTicketPrintData, showPayConfirm,
  } = useSelector((store) => store.ticketPaid);
  const { printError, printSuccess } = useSelector((store) => store.printTicket);
  const {
    success, msg, isPlaceBetLoading, placeBets,
  } = useSelector((store) => store.placeBets);
  const { successGetBalance, isbalanceLoading, errorGetBalance } = useSelector((store) => store.balance);
  const {
    showBonRechargeConfirm, bonRechargeSuccess, errorBonRecharge, isBonRechargeLoading, bonRechargeData,
  } = useSelector((store) => store.bonRechargeData);
  const { checkWithdrawLoading } = useSelector((store) => store.withdrawalChecked);
  const {
    successfetchTicket, ticketData, canCancel, canPay, verifyTicketMsg,
    isTicketLoading,
  } = useSelector((store) => store.ticketData);
  const {
    rechargeValue, showRechargeConfirm, errorRecharge, isdepositLoading, rechargeSuccess,
    depositData,
  } = useSelector((store) => store.depositData);
  const { agentCode } = useSelector((store) => store.voucherProviders);
  const {
    checkMsg, canWithdraw, canNotWithdraw, failedCheckWithdraw, cac, wcd,
  } = useSelector((store) => store.withdrawalChecked);
  const { withdrawWithCodeLoading, withdrawWithCodeData, canPrint } = useSelector((store) => store.withdrawWithCode);
  const { loadingHistory, histMsg } = useSelector((store) => store.history);
  const { resetLoading, resetMessage, resetSuccessMsg } = useSelector((store) => store.resetPinData);
  const {
    accountCreation, accountValidation, resendActivationCode,
  } = useSelector((store) => store.customerAccount);

  let ticketValue;
  if (betType === 'SIMPLE') {
    ticketValue = 1;
  } else if (betType === 'MULTIPLE') {
    ticketValue = 2;
  } else if (betType === 'MULTI_SIMPLE') {
    ticketValue = 5;
  } else {
    ticketValue = 3;
  }

  let amt = (Number(stake) === 0 ? Number(minStake) : Number(stake));

  let retailBonusParams = {};

  if (agentBonus.length > 0) {
    retailBonusParams = { bonid: agentBonus };
  } else if (Object.keys(retail).length > 0) {
    retailBonusParams = retail;
  }

  if (multipleSimple) {
    amt = [...simpleTickets].map((simpleTicket) => (Number(simpleTicket.stake) === 0 ? Number(minStake) : Number(simpleTicket.stake))).join('S');
  }

  const betObjList = [{
    uid: agentId,
    pwd: agentPwd,
    phn: agentPhone,
    pick,
    amt,
    chl: userObj.chl,
    tkt: ticketValue,
    ref: new Date().getTime(),
    dvr: 0.1,
    imei: userObj.imei,
    dtp: userObj.DT,
    kid: 1,
    vers: userObj.ver,
    ...retailBonusParams,
  }];

  const couponObj = {
    agentId,
    agentPwd,
    agentPhone,
    iref: new Date().getTime(),
    ref: `2*${couponAmt}`,
    internalRef: new Date().getTime(),
    reference: `${agentCode}*${couponAmt}*${agentPhone}`,
    imei: userObj.imei,
    chl: userObj.chl,
    ver: userObj.ver,
    deviceType: userObj.DT,
  };
  const directRechargeObj = {
    agentId,
    agentPwd,
    agentPhone,
    chl: userObj.chl,
    imei: userObj.imei,
    ver: userObj.ver,
    acc: rechargeValue.acc,
    amt: rechargeValue.amt,
  };
  const {
    msg43, msg44, msg45, msg46, msg47, msg48, msg1, msg50,
    msg52, msg53, msg49, msg51, msg159, msg196, msg197, msg198,
  } = translationKeys;
  let content = '';
  if (failedCancelTicket) {
    content = <MessageContent text={t(cancelTicketMsg)} color="red" />;
  }
  if (ticketNotRegistered) {
    content = <MessageContent text={t(cancelTicketMsg)} color="red" />;
  }
  if (ticketAlreadyCanceled) {
    content = <MessageContent text={t(cancelTicketMsg)} color="red" />;
  }
  if (ticketCanceled) {
    content = <MessageContent text={t(cancelTicketMsg)} color="green" />;
  }
  if (msg && msg.length > 0) {
    content = <MessageContent text={t(msg)} color="red" />;
  }
  if (previewMsg && previewMsg?.length > 0) {
    content = <MessageContent text={t(previewMsg)} color="red" />;
  }
  if (histMsg.length > 0) {
    content = <MessageContent text={t(histMsg)} color="red" />;
  }
  if (ticketPaid) {
    content = <MessageContent text={t(payTicketMsg)} color="green" />;
  }
  if (failedPayTicket) {
    content = <MessageContent text={t(payTicketMsg)} color="red" />;
  }
  if (showPayConfirm) {
    content = <MessageContent text={t(msg43)} color="red" />;
  }
  if (showCancelConfirm) {
    content = <MessageContent text={t(msg44)} color="red" />;
  }
  if (showRechargeConfirm) {
    content = <MessageContent text={`${t(msg45)} ${rechargeValue.amt}`} color="red" />;
  }
  if (errorRecharge.length > 0) {
    content = <MessageContent text={t(errorRecharge)} color="red" />;
  }
  if (resetMessage && resetMessage?.length > 0) {
    content = <MessageContent text={t(resetMessage)} color="red" />;
  }
  if (resetSuccessMsg?.length > 0) {
    content = <MessageContent text={t(resetSuccessMsg)} color="green" />;
  }
  if (showBonRechargeConfirm) {
    content = <MessageContent text={`${t(msg45)} ${couponAmt}`} color="red" />;
  }
  if (errorBonRecharge?.length > 0) {
    content = <MessageContent text={t(errorBonRecharge)} color="red" />;
  }
  if (errorGetBalance?.length > 0) {
    content = <MessageContent text={t(errorGetBalance)} color="red" />;
  }
  if (bonRechargeSuccess || (success && !printError) || rechargeSuccess || (accountValidation.success && printSuccess)) {
    content = <MessageContent text={t(msg46)} color="green" />;
  }
  if (canWithdraw || canNotWithdraw || failedCheckWithdraw) {
    content = <MessageContent text={t(checkMsg)} color="red" />;
  }
  if (canPrint) {
    content = <MessageContent text={t(msg47)} color="green" />;
  }
  if (verifyTicketMsg && verifyTicketMsg?.length > 0) {
    content = <MessageContent text={t(verifyTicketMsg)} color="red" />;
  }

  if (accountCreation.message.length > 0) {
    content = <MessageContent text={t(accountCreation.message)} color={accountCreation.success ? 'green' : 'red'} fontSize={accountCreation.success ? 14 : 16} />;
  }

  if (accountValidation.message.length > 0) {
    if (accountValidation.success && !printSuccess) {
      content = (
        <>
          <MessageContent text={t(accountValidation.message)} color="black" />
          <MessageContent text={t(msg196)} color="#777777" fontSize={12} />
        </>
      );
    }

    if (!accountValidation.success) {
      content = <MessageContent text={t(accountValidation.message)} color="red" />;
    }
  }

  if (resendActivationCode.message.length > 0) {
    content = <MessageContent text={t(resendActivationCode.message)} color={resendActivationCode.success ? 'green' : 'red'} />;
  }

  if (isCancelTicketLoading
     || isPlaceBetLoading || loadingHistory || isPaidLoading || isdepositLoading || isBonRechargeLoading
     || withdrawWithCodeLoading || isTicketLoading || checkWithdrawLoading || isbalanceLoading
     || resetLoading || resumeBetLoading
  ) {
    content = <MessageContent text={t(msg48)} color="red" />;
  }
  if (successGetBalance) {
    content = <SoldeContent arr={balance} />;
  }
  if (previewSuccess) {
    content = <ResumeBetContent arr={resumeBets} />;
  }

  if (successfetchTicket) {
    content = <VerifyTicketContent arr={ticketData} />;
  }
  if (printError) {
    content = <MessageContent text={t(msg159)} color="red" />;
  }

  const iconStyle = {
    color: 'black',
    fontSize: '1.5rem',
  };

  const timeout = 200;
  const closeModal = () => {
    setClassName('go-back-to-top');
    if (previewMsg && previewMsg.length > 0) {
      setTimeout(() => {
        dispatch(hideModal());
      }, timeout);
    }
    if (previewSuccess) {
      setTimeout(() => {
        dispatch(hideModal());
      }, timeout);
    }
    if (ticketAlreadyCanceled || ticketNotRegistered || ticketCanceled || failedCancelTicket) {
      setTimeout(() => {
        if (!ticketCanceled) {
          dispatch(resetTicketCanceled());
        }
        dispatch(hideVerifyModal());
      }, timeout);
    }
    if (ticketPaid || failedPayTicket) {
      setTimeout(() => {
        if (failedPayTicket) {
          dispatch(resetPayTicket());
        }
        dispatch(hideVerifyModal());
      }, timeout);
    }
    if (msg && msg.length > 0) {
      setTimeout(() => {
        dispatch(hideModal());
        dispatch(resetSuccess());
      }, timeout);
    }
    if (success && !printError) {
      setTimeout(() => {
        dispatch(refreshPrinting());
        dispatch(clearBetSlip());
        dispatch(resetSuccess());
        dispatch(calculateOdds());
        dispatch(calculateGains());
        dispatch(applyBonus());
        dispatch(hideModal());
      }, timeout);
    }

    if (printError) {
      setTimeout(() => {
        dispatch(refreshPrinting());
        if (success) {
          dispatch(clearBetSlip());
          dispatch(resetSuccess());
          dispatch(calculateOdds());
          dispatch(calculateGains());
          dispatch(applyBonus());
        }
        dispatch(hideModal());
      }, timeout);
    }

    if (accountValidation.success && printSuccess) {
      setTimeout(() => {
        dispatch(refreshPrinting());
        dispatch(resetAccountCreation());
        dispatch(resetAccountValidation());
        navigate('/dashboard/account-creation', { replace: true });
      }, timeout);
    }
    if (successfetchTicket) {
      setTimeout(() => {
        dispatch(hideVerifyModal());
      }, timeout);
    }
    if (verifyTicketMsg) {
      setTimeout(() => {
        dispatch(hideVerifyModal());
      }, timeout);
    }
    if (showPayConfirm) {
      setTimeout(() => {
        dispatch(hidePayConfirmBox());
      }, timeout);
    }
    if (showCancelConfirm) {
      setTimeout(() => {
        dispatch(hideCancelConfirmBox());
      }, timeout);
    }
    if (showRechargeConfirm && errorRecharge.length === 0) {
      setTimeout(() => {
        dispatch(hideRechargeConfirmBox());
      }, timeout);
    }
    if (errorRecharge.length > 0) {
      setTimeout(() => {
        dispatch(hideRechargeConfirmBox());
      }, timeout);
    }
    if (showBonRechargeConfirm && errorBonRecharge.length === 0) {
      setTimeout(() => {
        dispatch(hideBonRechargeConfirmBox());
      }, timeout);
    }
    if (errorBonRecharge.length > 0) {
      setTimeout(() => {
        dispatch(hideBonRechargeConfirmBox());
      }, timeout);
    }
    if (errorGetBalance.length > 0) {
      setTimeout(() => {
        dispatch(hideshowBalanceBox());
      }, timeout);
    }
    if (successGetBalance) {
      setTimeout(() => {
        dispatch(hideshowBalanceBox());
      }, timeout);
    }

    if (resetSuccessMsg.length > 0) {
      dispatch(hideResetMsg());
      dispatch(hideResetPinForm());
    }

    if (rechargeSuccess) {
      setTimeout(() => {
        dispatch(hideRechargeConfirmBox());
      }, timeout);
    }

    if (bonRechargeSuccess) {
      setTimeout(() => {
        dispatch(hideBonRechargeConfirmBox());
      }, timeout);
    }

    if (canPrint) {
      setTimeout(() => {
        dispatch(hideCheckWithdrawConfirmBox());
        dispatch(hideWithdrawWithCodeBox());
      }, timeout);
    }

    if (canWithdraw) {
      setTimeout(() => {
        dispatch(hideCheckWithdrawConfirmBox());
      }, timeout);
    }
    if (canNotWithdraw || failedCheckWithdraw) {
      setTimeout(() => {
        dispatch(hideCheckWithdrawConfirmBox());
      }, timeout);
    }
    if (histMsg && histMsg.length > 0) {
      setTimeout(() => {
        dispatch(hideHistModal());
      }, timeout);
    }
    if (resetMessage && resetMessage.length > 0) {
      setTimeout(() => {
        dispatch(hideResetMsg());
        if (!isMobile) {
          dispatch(showResetPinForm());
        }
      }, timeout);
    }

    if (accountCreation.message.length > 0) {
      setTimeout(() => {
        dispatch(resetAccountCreationMessage());
      }, timeout);
    }

    if (resendActivationCode.message.length > 0) {
      setTimeout(() => {
        dispatch(resetResendActivationCode());
      }, timeout);
    }

    if (accountValidation.message.length > 0) {
      setTimeout(() => {
        dispatch(resetAccountCreation());
        dispatch(resetAccountValidation());
        if (accountValidation.success) {
          navigate('/dashboard/account-creation', { replace: true });
        }
      }, timeout);
    }
  };

  const printAccountQRCode = () => {
    const { phoneNumber, accountCode } = accountValidation;

    const QRCodeLines = [
      '#_01_#',
      '--------------------------------',
      `${t(msg197)}: ${phoneNumber}`,
      `${t(msg198)}: ${accountCode}`,
      '--------------------------------',
      '#_03_#',
      accountCode,
      '--------------------------------',
      '       Powered by FlexBet',
      '--------------------------------',
    ];

    if (userAgent.toLowerCase().includes('native')) {
      getDeviceImei();
      printTicketData(QRCodeLines);
    } else {
      dispatch(printTicket(QRCodeLines));
    }
  };

  const userLanguage = navigator.language.split('-')[0];

  useEffect(() => {
    if (success) {
      if (userAgent.toLowerCase().includes('native')) {
        getDeviceImei();
        placeBets.forEach((placedBet) => {
          printTicketData(placedBet);
        });
      } else {
        placeBets.forEach((placedBet) => {
          dispatch(printTicket(placedBet));
        });
      }
    }
  }, [success, placeBets, userAgent, dispatch]);
  useEffect(() => {
    if (ticketPaid) {
      if (userAgent.toLowerCase().includes('native')) {
        getDeviceImei();
        printTicketData(paidTicketPrintData);
      } else {
        dispatch(printTicket(paidTicketPrintData));
      }
    }
  }, [ticketPaid, paidTicketPrintData, userAgent, dispatch]);
  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div
            id="modal"
            className="mx-auto col-md-6 col-lg-4 text-center text-capitalize modal-info"
          >
            <div className="tck-prev">
              <div className="ticket">
                <div className="img-set">
                  <img src={logo} alt="mathysbet logo" />
                </div>
                <div className="modal-content">
                  {content}
                </div>
              </div>
              <article className="actions text-center">

                {previewSuccess && (
                  <>
                    <button
                      type="button"
                      className="btn btnClose"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      <IoMdClose style={iconStyle} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        dispatch(placeBet(betObjList));
                        dispatch(hideModal());
                      }}
                    >
                      {t(msg49)}
                    </button>
                  </>
                )}
                {previewMsg && previewMsg.length > 0 && (
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                      if (previewMsg === tokenText) {
                        dispatch(logout());
                        localStorage.setItem('lang', userLanguage);
                        window.location.reload();
                      }
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                )}
                {(ticketAlreadyCanceled || ticketNotRegistered || ticketCanceled || failedCancelTicket) && (
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                      if (cancelTicketMsg === tokenText) {
                        dispatch(logout());
                        localStorage.setItem('lang', userLanguage);
                        window.location.reload();
                      }
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>

                )}
                {(ticketPaid || failedPayTicket) && !printError && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (payTicketMsg === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                      window.location.reload();
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {msg && msg.length > 0 && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (msg === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                      window.location.reload();
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                ) }
                {(success || printError || (accountValidation.success && printSuccess)) && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {successfetchTicket && canPay && (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    dispatch(hideVerifyModal());
                    dispatch(showPayConfirmBox());
                  }}
                >
                  {t(msg50)}
                </button>
                )}
                {(successfetchTicket && canCancel) && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    dispatch(hideVerifyModal());
                    dispatch(showCancelConfirmBox());
                  }}
                >
                  {t(msg51)}
                </button>
                )}
                {successfetchTicket && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => closeModal()}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {verifyTicketMsg && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (verifyTicketMsg === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                      window.location.reload();
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}

                {showPayConfirm && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(payTicket({
                        ...payObj,
                        refCode,
                        agentId,
                        agentPwd,
                        agentPhone,
                      }));
                    }}
                  >
                    {t('Oui')}
                  </button>
                </>
                )}

                {showCancelConfirm && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(cancelTicket({
                        ...cancelObj,
                        agentId,
                        agentPwd,
                        agentPhone,
                        dtp: userObj.DT,
                        bid: ticketNumber,
                      }));
                      dispatch(hideCancelConfirmBox());
                    }}
                  >
                    {t(msg52)}
                  </button>
                </>
                )}

                {showRechargeConfirm && errorRecharge.length === 0 && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                    disabled={isdepositLoading}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(hideRechargeConfirmBox());
                      dispatch(directRecharge(directRechargeObj));
                    }}
                    disabled={isdepositLoading}
                  >
                    {t(msg52)}
                  </button>
                </>
                )}

                {errorRecharge.length > 0 && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (errorRecharge === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                      window.location.reload();
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {rechargeSuccess && !printError && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success print"
                    onClick={() => {
                      dispatch(hideRechargeConfirmBox());

                      if (userAgent.toLowerCase().includes('native')) {
                        getDeviceImei();
                        printTicketData(depositData);
                      } else {
                        dispatch(printTicket(depositData));
                      }
                    }}
                  >
                    {t(msg53)}
                  </button>
                </>
                )}
                {showBonRechargeConfirm && errorBonRecharge.length === 0 && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(hideBonRechargeConfirmBox());
                      dispatch(bonRecharge(couponObj));
                    }}
                  >
                    {t(msg52)}
                  </button>
                </>
                )}

                {(accountCreation.message.length > 0 || resendActivationCode.message.length > 0) && (
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                )}

                {accountValidation.message.length > 0
                  && accountValidation.success && !printSuccess && !printError
                    && (
                      <button
                        type="button"
                        className="btn btn-success print"
                        onClick={() => {
                          printAccountQRCode();
                        }}
                      >
                        {t(msg53)}
                      </button>
                    )}

                {accountValidation.message.length > 0 && !accountValidation.success && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}

                {errorBonRecharge.length > 0 && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (errorBonRecharge === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                      window.location.reload();
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {bonRechargeSuccess && !printError && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success print"
                    onClick={() => {
                      dispatch(hideBonRechargeConfirmBox());

                      if (userAgent.toLowerCase().includes('native')) {
                        getDeviceImei();
                        printTicketData(bonRechargeData);
                      } else {
                        dispatch(printTicket(bonRechargeData));
                      }
                    }}
                  >
                    {t(msg53)}
                  </button>
                </>
                )}
                { errorGetBalance.length > 0 && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (errorGetBalance === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                      window.location.reload();
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {successGetBalance && !printError && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success print"
                    onClick={() => {
                      dispatch(hideshowBalanceBox());

                      if (userAgent.toLowerCase().includes('native')) {
                        getDeviceImei();
                        printTicketData(balance);
                      } else {
                        dispatch(printTicket(balance));
                      }
                    }}
                  >
                    {t(msg53)}
                  </button>
                </>
                )}
                {canWithdraw && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(hideCheckWithdrawConfirmBox());
                      dispatch(withdrawWithCode({
                        ...checkWithdrawObj,
                        wcd,
                        cac,
                        agentId,
                        agentPwd,
                        agentPhone,
                      }));
                    }}
                  >
                    {t(msg50)}
                  </button>
                </>
                )}
                {(canNotWithdraw || failedCheckWithdraw) && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (checkMsg === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                      window.location.reload();
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>
                )}
                {canPrint && !printError && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success print"
                    onClick={() => {
                      dispatch(hideCheckWithdrawConfirmBox());
                      dispatch(hideWithdrawWithCodeBox());

                      if (userAgent.toLowerCase().includes('native')) {
                        getDeviceImei();
                        printTicketData(withdrawWithCodeData);
                      } else {
                        dispatch(printTicket(withdrawWithCodeData));
                      }
                    }}
                  >
                    {t(msg53)}
                  </button>
                </>
                )}

                {histMsg.length > 0 && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (histMsg === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                      window.location.reload();
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>

                )}
                {resetMessage.length > 0 && (
                <button
                  type="button"
                  className="btn btnClose"
                  onClick={() => {
                    closeModal();
                    if (resetMessage === tokenText) {
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                      window.location.reload();
                    }
                  }}
                >
                  <IoMdClose style={iconStyle} />
                </button>

                )}
                {resetSuccessMsg.length > 0 && (
                <>
                  <button
                    type="button"
                    className="btn btnClose"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <IoMdClose style={iconStyle} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(hideResetMsg());
                      dispatch(hideResetPinForm());
                      dispatch(logout());
                      localStorage.setItem('lang', userLanguage);
                      window.location.reload();
                    }}
                  >
                    {t(msg1)}
                  </button>
                </>
                )}
              </article>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isMobile: PropTypes.bool,
};

Modal.defaultProps = {
  isMobile: false,
};

export default Modal;
