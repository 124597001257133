import React, { useEffect, useState } from 'react';
import TopBar from './TopBar';
import Body from './Body';

const NavigationPanel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <>
      <TopBar isMobile={isMobile} />
      <Body isMobile={isMobile} />
    </>
  );
};

export default NavigationPanel;
