/* eslint  max-len: 0 */

export const printTicketData = (data) => {
  const charsPerLine = 32;

  if (Array.isArray(data)) {
    const formattedData = data.map((line) => {
      if (line.includes(': ')) {
        const content = line.split(': ');
        const middleSpaceCount = charsPerLine - content[0].length - content[1].length;

        if (middleSpaceCount > 0) {
          return content[0] + ''.padEnd(middleSpaceCount, ' ') + content[1];
        }
        return `${content[0]} ${content[1]}`;
      }
      return line;
    });

    if (window.Android) {
      window.Android.printTicket(JSON.stringify(formattedData));
    }
  }
};

export const getDeviceImei = () => (window.Android && window.Android.getDeviceImei());
