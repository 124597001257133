/* eslint  max-len: 0 */
import { getDeviceImei } from 'components/helpers/printTicketData';
import getLanguage from 'components/helpers/getLanguage';

const { userAgent } = window.navigator;
const DT = userAgent.toLowerCase().includes('native') ? 2 : 'OLD_VERSION';
const chl = userAgent.toLowerCase().includes('native') ? 6 : 8;
const webimei = 'OLD_VERSION';
const date = new Date();
const timestamp = date.getTime();
const imei = getDeviceImei();
const ver = 3.7;
const lang = getLanguage();

const userObj = {
  lang,
  chl,
  ver,
  imei: (imei && (imei !== undefined || imei !== null)) ? imei : webimei,
  DT,
};
const cancelObj = {
  lang,
  chl,
  dvr: 0.1,
  kid: 1,
  ver,
  opt: 'CANCEL_BY_AGENT',
  imei: (imei && (imei !== undefined || imei !== null)) ? imei : webimei,
};
const payObj = {
  type: '1',
  imei: (imei && (imei !== undefined || imei !== null)) ? imei : webimei,
};
const balanceObj = {
  balanceType: '1',
  mode: 'INCONNU',
  ver,
};
const bonRechargeObj = {
  lang,
  iref: timestamp,
  chl,
  ver,
};
const checkWithdrawObj = {
  lang,
  erf: timestamp,
  chl,
  imei: (imei && (imei !== undefined || imei !== null)) ? imei : webimei,
};
const withdrawWithCodeObj = {
  lang,
  erf: timestamp,
  chl,
};

export {
  payObj, cancelObj, balanceObj, bonRechargeObj, checkWithdrawObj, withdrawWithCodeObj, userObj, chl, ver, webimei,
};
