import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const VerifyTicketContent = ({ arr }) => (
  <div className={`preview-data-solde ${arr.length > 1 ? 'flex' : ''}`}>
    {arr && arr.length > 0 && arr[0].data && arr[0].data.map((item) => {
      const { data } = item;
      return (

        data?.length > 0 ? data.slice(5).map((d) => d && (
        <span className="ticket-info" key={uuidv4()}>{d}</span>
        )) : null

      );
    })}
  </div>
);

const ResumeBetContent = ({ arr }) => (
  <div className={`preview-data-solde ${arr.length > 1 ? 'flex' : ''}`}>
    {
    arr.length > 1 ? arr.map((info, index) => info && (
      <div className="ticket-section" key={`Ticket-resume-${index + 1}`}>
        <span className="ticket-number" key={uuidv4()}>{`Ticket ${index + 1}`}</span>
        {
          (info?.length > 0 ? info.slice(5).map((item) => item && (
            <span className="ticket-info" key={uuidv4()}>{item}</span>
          )) : null)
        }
      </div>
    )) : null
    }
    {
    arr.length === 1 ? arr.map((info) => (
      info?.length > 0 ? info.slice(5).map((item) => item && (
        <span className="ticket-info" key={uuidv4()}>{item}</span>
      )) : null
    )) : null
    }
  </div>
);
const SoldeContent = ({ arr }) => (
  <div className="preview-data-solde">
    {(arr && arr.length > 0) ? arr.slice(2).map((item) => item && (
      <span className="ticket-info" key={uuidv4()}>{item}</span>
    )) : null}
  </div>
);
const MessageContent = ({ text, color, fontSize }) => (
  <p style={{ color, fontSize: `${fontSize}px` }}>{text}</p>
);

MessageContent.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
};

MessageContent.defaultProps = {
  fontSize: 16,
};

VerifyTicketContent.propTypes = {
  arr: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
};

ResumeBetContent.propTypes = {
  arr: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
SoldeContent.propTypes = {
  arr: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export {
  VerifyTicketContent, ResumeBetContent, MessageContent, SoldeContent,
};
