import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parsePhoneNumber from 'libphonenumber-js';
import translationKeys from 'translations/translationKeys';
import { useSelector } from 'react-redux';
import qrCodeImage from '../../../../assets/images/mathys/svg/qr-code.svg';

const { useTranslation } = require('react-i18next');

const RetailBonusBetSection = ({
  isMobile, setAgentBonus, setRetailBetInfo,
}) => {
  const { t } = useTranslation();
  const {
    msg187, msg201, msg202, msg203, msg204, msg205, msg206, msg207, msg210, msg211,
  } = translationKeys;
  const [betSelection, setBetSelection] = useState(null);
  const [clientPhoneNumber, setClientPhoneNumber] = useState('');
  const [clientAccountCode, setClientAccountCode] = useState('');
  const [toggleClientBonus, setToggleClientBonus] = useState(false);
  const [clientBonus, setClientBonus] = useState('');
  const [invalidNumber, setInvalidNumber] = useState(true);
  const [invalidAccCode, setInvalidAccCode] = useState(true);
  const [countryCode, setCountryCode] = useState('+243');
  const [country, setCountry] = useState('DC');
  const [phoneLength, setPhoneLength] = useState(9);

  const { data } = useSelector((store) => store.systemConfigs);

  const getValidPhoneNumber = (event) => {
    const number = event.target.value;
    setClientPhoneNumber(number);
    if (number.length === phoneLength) {
      const validator = parsePhoneNumber(number, country);
      if (validator?.isValid()) {
        setInvalidNumber(false);
        setRetailBetInfo({ rphn: number });
      } else {
        setInvalidNumber(true);
        setRetailBetInfo({});
      }
    } else {
      setInvalidNumber(true);
      setRetailBetInfo({});
    }
  };

  const getClientBonus = (event) => {
    setClientBonus(event.target.value);
    if (!invalidNumber) {
      setRetailBetInfo({ rphn: clientPhoneNumber, bonid: event.target.value });
    }

    if (!invalidAccCode) {
      setRetailBetInfo({ rcc: clientAccountCode, bonid: event.target.value });
    }
  };

  const toggleClientBonusUse = () => {
    if (toggleClientBonus) {
      if (!invalidNumber) {
        setRetailBetInfo({ rphn: clientPhoneNumber });
      }

      if (!invalidAccCode) {
        setRetailBetInfo({ rcc: clientAccountCode });
      }
    }
    setToggleClientBonus(!toggleClientBonus);
  };

  const resetRetailRef = () => {
    setClientPhoneNumber('');
    setClientAccountCode('');
    setAgentBonus({});
    setInvalidNumber(true);
    setInvalidAccCode(true);
    setToggleClientBonus(false);
  };

  const getClientCode = (event) => {
    const code = event.target.value;
    setClientAccountCode(code);
    if (code.length === 6) {
      setInvalidAccCode(false);
      setRetailBetInfo({ rcc: code });
    } else {
      setInvalidAccCode(true);
      setRetailBetInfo({});
    }
  };

  const selectSection = (section) => {
    resetRetailRef();
    if (section === betSelection) {
      setBetSelection(null);
    } else {
      setBetSelection(section);
    }
  };

  const selectAgentBonus = (event) => {
    const bonusSelected = event.target.value;

    if (bonusSelected.length > 0) {
      setAgentBonus({ bonid: bonusSelected });
    } else {
      setAgentBonus({});
    }
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setCountryCode(data.DEFAULT_COUNTRY_CODE);
      setPhoneLength(Number(data.PHONE_NUMBER_LENGTH));
      const randomPhoneNumber = '1'.repeat(phoneLength);
      const { country } = parsePhoneNumber(countryCode + randomPhoneNumber);
      setCountry(country);
    }
  }, [data]);

  return (
    <div className={`retail-bonus-bet-container${isMobile ? ' mobile' : ''}`}>
      <div className="retail-bet-section">
        <div className="ctn-input checkbox">
          <label htmlFor="retail-bet-checkbox" id="show-retail-bet">
            <input
              type="checkbox"
              id="retail-bet-checkbox"
              checked={betSelection === 'retail'}
              onChange={() => { selectSection('retail'); }}
            />
            <span>{t(msg201)}</span>
          </label>
        </div>
        {betSelection === 'retail' && (
          <div className={`retail-bet-form${isMobile ? ' mobile' : ''}`}>
            <section className="step">
              <div className={`retail-ref${toggleClientBonus ? ' closed' : ''}`}>
                <ul className="ref-selection nav nav-tabs" id="myTab" role="tablist">
                  <li className="radio-option" role="presentation">
                    <input type="radio" name="tab" id="account-code" data-bs-toggle="tab" data-bs-target="#account-code-pane" role="tab" aria-controls="account-code-pane" aria-selected="true" defaultChecked onClick={resetRetailRef} />
                    <label htmlFor="account-code">{t(msg203)}</label>
                  </li>
                  <li className="radio-option" role="presentation">
                    <input type="radio" name="tab" id="tel-number" data-bs-toggle="tab" data-bs-target="#tel-number-pane" role="tab" aria-controls="tel-number-pane" aria-selected="false" onClick={resetRetailRef} />
                    <label htmlFor="tel-number">{t(msg204)}</label>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="account-code-pane" role="tabpanel" aria-labelledby="account-code" tabIndex="0">
                    <div className="input-wrap">
                      <input type="text" placeholder={t(msg205)} className="form-control" value={clientAccountCode} onChange={getClientCode} />
                      {clientAccountCode.length > 1 && invalidAccCode && (
                      <div className="error-block">
                        <span>{t(msg206)}</span>
                      </div>
                      )}
                    </div>
                    { isMobile && (
                    <button className="btn btn-icon qr-code" type="button">
                      <span className="icon" style={{ maskImage: `url(${qrCodeImage})` }} />
                    </button>
                    )}
                  </div>
                  <div className="tab-pane fade" id="tel-number-pane" role="tabpanel" aria-labelledby="tel-number" tabIndex="0">
                    <div className="tel-input-group">
                      <div className="country">
                        <span>{countryCode}</span>
                      </div>
                      <div className="input-field">
                        <input
                          placeholder="ex: 892651248"
                          id="phoneNumber"
                          type="tel"
                          value={clientPhoneNumber}
                          onChange={getValidPhoneNumber}
                          onKeyDown={(e) => !(/^\d+$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') && e.preventDefault()}
                          className={`form-control${(invalidNumber && clientPhoneNumber.length > 0) ? ' error' : ''}`}
                        />
                        {invalidNumber && clientPhoneNumber.length > 0 && (
                        <div className="error-block">
                          <span>{t(msg187)}</span>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ctn-input checkbox customer-bonus">
                <label htmlFor="client-bonus-checkbox" id="show-client-bonus">
                  <input
                    type="checkbox"
                    id="client-bonus-checkbox"
                    disabled={invalidAccCode && invalidNumber}
                    checked={toggleClientBonus}
                    onClick={toggleClientBonusUse}
                  />
                  <span>{t(msg211)}</span>
                </label>
                {toggleClientBonus && (
                <>
                  <input type="text" placeholder={t(msg207)} className="form-control" value={clientBonus} onChange={getClientBonus} />
                  {/* <div className="actions">
                    <button className="btn btn-secondary no-border" type="button">
                      <span>{t(msg208)}</span>
                    </button>
                  </div> */}
                </>
                )}
              </div>
            </section>
          </div>
        )}
      </div>
      <div className="agent-bonus-section">
        <div className="ctn-input checkbox">
          <label htmlFor="agent-bonus-checkbox" id="show-agent-bonus">
            <input
              type="checkbox"
              id="agent-bonus-checkbox"
              checked={betSelection === 'bonus'}
              onChange={() => { selectSection('bonus'); }}
            />
            <span>{t(msg202)}</span>
          </label>
        </div>
        {betSelection === 'bonus' && (
        <select defaultValue="" className="form-control bonus-selection" onChange={selectAgentBonus}>
          <option value="">{t(msg210)}</option>
        </select>
        )}
      </div>
    </div>
  );
};

RetailBonusBetSection.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  setAgentBonus: PropTypes.func.isRequired,
  setRetailBetInfo: PropTypes.func.isRequired,
};

export default RetailBonusBetSection;
