import React from 'react';
import { Navigate } from 'react-router';
import { useSelector } from 'react-redux';
import NavigationPanel from './navigationPanel/NavigationPanel';

const Dashboard = () => {
  const {
    isLoggedIn,
  } = useSelector((store) => store.login);

  const token = sessionStorage.getItem('token');

  return (isLoggedIn || token) ? <NavigationPanel /> : <Navigate to="../../login" replace />;
};

export default Dashboard;
