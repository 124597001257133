/* eslint prefer-destructuring: 0 */
/* eslint jsx-a11y/label-has-associated-control: 0 */
/* eslint  jsx-a11y/no-static-element-interactions: 0 */
/* eslint  jsx-a11y/click-events-have-key-events: 0 */
/* eslint  max-len: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import {
  deleteFromBetSlip, updateStake,
  clearBetSlip, stepStake,
  calculateGains,
  calculateOdds,
  applyBonus,
  calculateTax,
  setRetailBet,
  setClientBonus,
} from 'redux/configs/betConfigsSlice';

import { resumeBet, showModal } from 'redux/place-bet/resumeBetsSlice';
import { formatCurrency } from 'components/helpers/currencyOptions';
// import { getDeviceImei } from 'components/helpers/printTicketData';
import { userObj } from 'components/common/requestBody';
// import { IoMdClose } from 'react-icons/io';
import '../../../../styles/_rightsidebar.scss';
import plus from 'assets/images/mathys/svg/plus.svg';
import ShowAlert from 'components/common/ShowAlert';
import Modal from 'components/common/Modal';
import ResetPinComponent from 'components/sessions/ResetPinComponent';
import PropTypes from 'prop-types';
// import trash from '../../../../assets/images/mathys/svg/trash.svg';
import { getClientBonus } from 'redux/client-bonus/clientBonusSlice';
import defaultImage from '../../../../assets/images/mathys/png/football.png';
import dash from '../../../../assets/images/mathys/svg/dash.svg';
import downIcon from '../../../../assets/images/mathys/svg/chevron-down.svg';
import baseUrls from '../../../../config';
import BetSlipHeader from './BetSlipHeader';
import RetailBonusBetSection from './RetailBonusBetSection';

const BetSlip = ({ isMobile, toggleBetSlip, closeBetSlip }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const { agentId, agentPwd, agentPhone } = useSelector((store) => store.login);
  const [toggleBetDetails, setToggleBetDetails] = useState(false);
  const { printError } = useSelector((store) => store.printTicket);
  const {
    odds, bets, betType, stake, finalWinnings,
    multiple, simple, multipleSimple, exciseTaxConfig, TVA,
    potentialWinnings, potentialWinningsWithBonus, minStake, maxStake, exciseTax, tvaConfig, minEvents,
    alerts, integral, bonus, integralTickets, bettingOptions, simpleTickets,
    numIntegralTickets, pick, stakeWithoutTax, bonusPercent, stepValue, validMultiple, multipleIntegral,
  } = useSelector((store) => store.betSlip);
  const { resumeBetLoading, betStatus, showPreview } = useSelector((store) => store.resumeBets);
  const {
    successfetchTicket, verifyTicketMsg, isTicketLoading, isErrorVerifyTckt,
  } = useSelector((store) => store.ticketData);
  const { showRechargeConfirm, rechargeSuccess, isdepositLoading } = useSelector((store) => store.depositData);
  const { checkWithdrawLoading } = useSelector((store) => store.withdrawalChecked);
  const {
    accountCreation, accountValidation, resendActivationCode,
  } = useSelector((store) => store.customerAccount);
  const {
    showBonRechargeConfirm, bonRechargeSuccess, errorBonRecharge, isBonRechargeLoading,
  } = useSelector((store) => store.bonRechargeData);
  const { successGetBalance, isbalanceLoading, errorGetBalance } = useSelector((store) => store.balance);
  const { canWithdraw, canNotWithdraw, failedCheckWithdraw } = useSelector((store) => store.withdrawalChecked);
  const { isPlaceBetLoading } = useSelector((store) => store.placeBets);
  const { isCancelTicketLoading } = useSelector((store) => store.ticketCanceled);
  const {
    withdrawWithCodeLoading, canPrint,
  } = useSelector((store) => store.withdrawWithCode);
  const {
    ticketPaid, showPayConfirm, failedPayTicket, isPaidLoading,
  } = useSelector((store) => store.ticketPaid);
  const { loadingHistory, histMsg } = useSelector((store) => store.history);
  const {
    ticketCanceled, showCancelConfirm, failedCancelTicket,
    ticketNotRegistered, ticketAlreadyCanceled,
  } = useSelector((store) => store.ticketCanceled);
  const { success, msg } = useSelector((store) => store.placeBets);
  const {
    resetLoading, resetMessage, resetSuccessMsg, resetPinForm,
  } = useSelector((store) => store.resetPinData);
  const { currencyConfigs } = useSelector((store) => store.currencyConfigs);
  const imgUrl = baseUrls.BASE_URL_IMG;
  const betTypes = (`${bettingOptions}`).split(',');
  const simpleBetIndex = betTypes.lastIndexOf('SIMPLE');
  const multipleBetIndex = betTypes.lastIndexOf('MULTIPLE');
  const multipleSimpleBetIndex = betTypes.lastIndexOf('MULTI_SIMPLE');
  const lang = getLanguage();
  const [retailBetParam, setRetailBetParam] = useState({});
  const [agentBonusParam, setAgentBonusParam] = useState({});

  const switchBetDetails = () => {
    setToggleBetDetails(!toggleBetDetails);
  };

  const handleInputPrice = (e) => {
    dispatch(updateStake(e.target.value));
  };

  const handleSubInputPrice = (e, index) => {
    dispatch(updateStake({ stake: e.target.value, index }));
  };

  const setAgentBonus = (agentBonus) => {
    setRetailBetParam({});
    dispatch(setRetailBet({}));
    setAgentBonusParam(agentBonus);
    dispatch(setClientBonus(agentBonus?.bonid ?? ''));
  };

  const setRetailBetInfo = (retailBetParam) => {
    dispatch(setRetailBet(retailBetParam));
    setRetailBetParam(retailBetParam);
    setAgentBonusParam({});
    dispatch(setClientBonus(''));
  };

  const recompute = () => {
    dispatch(calculateOdds());
    dispatch(calculateGains());
    dispatch(applyBonus());
    dispatch(calculateTax());
  };

  const sendResumeBet = () => {
    let ticketValue = 3;
    let betObjList = [];
    if (betType === 'SIMPLE') {
      ticketValue = 1;
    } else if (betType === 'MULTIPLE') {
      ticketValue = 2;
    } else if (betType === 'MULTI_SIMPLE') {
      ticketValue = 5;
    }

    let amt = (Number(stake) === 0 ? Number(minStake) : Number(stake));

    if (multipleSimple) {
      amt = [...simpleTickets].map((simpleTicket) => (Number(simpleTicket.stake) === 0 ? Number(minStake) : Number(simpleTicket.stake))).join('S');
    }

    betObjList = [{
      lang,
      uid: agentId,
      pwd: agentPwd,
      phn: agentPhone,
      pick,
      amt,
      chl: userObj.chl,
      tkt: ticketValue,
      ref: new Date().getTime(),
      dvr: 0.1,
      imei: userObj.imei,
      dtp: userObj.DT,
      kid: 1,
      vers: userObj.ver,
      ...retailBetParam,
      ...agentBonusParam,
    }];

    dispatch(resumeBet(betObjList));
    dispatch(showModal());
  };

  useEffect(() => {
    if (!multipleSimple) {
      const timeoutDuration = 500;
      const focusInput = () => {
        const timeout = setTimeout(() => {
          if (inputRef?.current) {
            inputRef.current.focus();
          }
        }, timeoutDuration);
        return () => clearTimeout(timeout);
      };

      if (bets.length > 0) {
        focusInput();
      }
    }
  }, [bets, multipleSimple]);

  useEffect(() => {
    dispatch(calculateOdds());
    dispatch(calculateGains());
    dispatch(applyBonus());
    dispatch(calculateTax());
  }, [stake, bets, integral, betType, dispatch]);

  useEffect(() => {
    if (bets.length === 0) {
      dispatch(updateStake(minStake));
    }
  }, [bets, minStake, dispatch]);

  useEffect(() => {
    if (agentId) {
      dispatch(getClientBonus(agentId));
    }
  }, [agentId, dispatch]);

  let color;
  if (betStatus === 'Success!') {
    color = 'green';
  } else if (betStatus === 'Failed!') {
    color = 'red';
  } else { color = '#fff'; }
  const {
    // msg74,
    msg75, msg76, msg77, msg79,
    // msg153,
    msg145, msg149, msg157, msg158, msg162, msg181,
  } = translationKeys;

  const inValidStake = () => ((stake < minStake) || (stake > maxStake) || (stake % stepValue !== 0));
  const inValidSubStake = (value) => ((value < minStake) || (value > maxStake) || (value % stepValue !== 0));
  const validMultipleSimple = () => (simpleBetIndex > -1 && multipleBetIndex > -1 && multipleSimpleBetIndex > -1);
  const inValidMultipleSimple = () => multipleSimple && simpleTickets.some((simpleTicket) => inValidSubStake(simpleTicket.stake));
  const inValidMultiple = () => (!validMultiple && (multiple || (multipleIntegral && integral)));
  const inValidBetSlip = () => (inValidStake() || inValidMultiple() || bets.length < 1 || inValidMultipleSimple());
  const canSwitchToMultiple = () => (bets.length >= minEvents);

  const slipBody = () => {
    if ((simple || multiple) && !integral && !multipleSimple) {
      return (
        <ul className="match-list list-unstyled">
          { bets.map((item) => {
            const {
              match, odds, team, market, uniqueId, matchNumber,
            } = item;
            return (
              <li key={uniqueId} className="bet">
                <img src={`${imgUrl}${match?.info?.sport?.icon}` || defaultImage} alt="" />
                <div className="block-left">
                  <div className="match">
                    <span>{`${match?.info?.category?.name} - ${match?.info?.tournament?.name}`}</span>
                    <button
                      type="button"
                      className="btn btn-close"
                      onClick={() => {
                        dispatch(deleteFromBetSlip(uniqueId));
                      }}
                    />
                    <span className="span-2">
                      {match?.info?.teamhome?.name}
                      {' '}
                      -
                      {' '}
                      {match?.info?.teamaway?.name}
                    </span>
                  </div>
                  <div className="market">
                    <span>
                      {market}
                      {' '}
                      -
                      {' '}
                      {team}
                    </span>
                    <span>{odds}</span>
                  </div>
                  <div className="number">
                    <span>{ matchNumber}</span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
    return (
      <ul className="ticket-list list-unstyled">
        {integral ? (integralTickets.map((integralTicket, index) => {
          const betIndexes = integralTicket.bets;
          const indexKey = betIndexes.reduce((acc, index) => acc + index.toString(), '');
          return (
            <li className="ticket" key={indexKey}>
              <span className="title">{`Ticket ${index + 1}`}</span>
              <div className="ctn-events" key={indexKey}>
                <ul className="match-list list-unstyled">
                  {(betIndexes?.length > 0 && bets.length > 0) ? betIndexes.map((betIndex) => {
                    const {
                      match, odds, team, market, uniqueId, matchNumber,
                    } = bets[betIndex];
                    return (
                      <li key={`${indexKey}-${uniqueId}`} className="bet">
                        <img src={`${imgUrl}${match?.info?.sport?.icon}` || defaultImage} alt="" />
                        <div className="block-left">
                          <div className="match no-close">
                            <span>{`${match?.info?.category?.name} - ${match?.info?.tournament?.name}`}</span>
                            <span>
                              {match?.info?.teamhome?.name}
                              {' '}
                              -
                              {' '}
                              {match?.info?.teamaway?.name}
                            </span>
                          </div>
                          <div className="market">
                            <span>
                              {market}
                              {' '}
                              -
                              {' '}
                              {team}
                            </span>
                            <span>{odds}</span>
                          </div>
                          <div className="number">
                            <span>{ matchNumber}</span>
                          </div>
                        </div>
                      </li>
                    );
                  }) : null}
                </ul>
              </div>
              <div className="zone-bet">
                <div className="line">
                  <span className="lib">{t(msg157)}</span>
                  <span className="val">{integralTicket.odds}</span>
                </div>
                <div className="line">
                  <span className="lib">{t(msg76)}</span>
                  <span className="val">
                    {inValidStake() ? '-' : formatCurrency(integralTicket.potentialWinnings, currencyConfigs)}
                  </span>
                </div>
                {integralTicket.bonus > 0 && (
                  <div className="line">
                    <span className="lib">
                      Bonus(
                      {`${integralTicket.bonusPercent}%`}
                      )
                    </span>
                    <span className="val">
                      {inValidStake() ? '-' : formatCurrency(integralTicket.bonus, currencyConfigs)}
                    </span>
                  </div>
                )}
                {integralTicket.bonus > 0 && (
                  <div className="line">
                    <span className="lib">
                      {`${t(msg158)} + Bonus(${bonusPercent}%)`}
                    </span>
                    <span className="val">
                      {inValidStake() ? '-' : formatCurrency(integralTicket.potentialWinningsWithBonus, currencyConfigs)}
                    </span>
                  </div>
                )}
                {integralTicket.TVA > 0 && (
                  <div className="line">
                    <span className="lib">{`${integralTicket.tvaConfig.value}% ${integralTicket.tvaConfig.type}`}</span>
                    <span className="val">
                      {inValidStake() ? '-' : formatCurrency(integralTicket.TVA, currencyConfigs)}
                    </span>
                  </div>
                )}
                <div className="line">
                  <span className="lib">{t(msg149)}</span>
                  <span className="val">
                    {inValidStake() ? '-' : formatCurrency(integralTicket.finalWinnings, currencyConfigs)}
                  </span>
                </div>
              </div>
            </li>
          );
        })
        ) : (
          simpleTickets?.length > 0 && bets?.length > 0 && simpleTickets.map((simpleTicket, index) => {
            const betIndex = simpleTicket.betIndex;
            const {
              match, odds, team, market, uniqueId, matchNumber,
            } = bets[betIndex];
            return (
              <li className="ticket" key={`simple-ticket-${betIndex}`}>
                <span className="title">{`Ticket ${index + 1}`}</span>
                <div className="ctn-events" key={`simple-ticket-info-${betIndex}`}>
                  <ul className="match-list list-unstyled">
                    <li key={`${betIndex}-${uniqueId}`} className="bet">
                      <img src={`${imgUrl}${match?.info?.sport?.icon}` || defaultImage} alt="" />
                      <div className="block-left">
                        <div className="match">
                          <span>{`${match?.info?.category?.name} - ${match?.info?.tournament?.name}`}</span>
                          <button
                            type="button"
                            className="btn btn-close"
                            onClick={() => {
                              dispatch(deleteFromBetSlip(uniqueId));
                            }}
                          />
                          <span className="span-2">
                            {match?.info?.teamhome?.name}
                            {' '}
                            -
                            {' '}
                            {match?.info?.teamaway?.name}
                          </span>
                        </div>
                        <div className="market">
                          <span>
                            {market}
                            {' '}
                            -
                            {' '}
                            {team}
                          </span>
                          <span>{odds}</span>
                        </div>
                        <div className="number">
                          <span>{ matchNumber}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="zone-bet">
                  <div className="line">
                    <span className="lib">{t(msg157)}</span>
                    <span className="val">{simpleTicket.odds}</span>
                  </div>
                  <div className={isMobile ? 'stake' : 'line'}>
                    {!isMobile && (<span className="lib">{t(msg75)}</span>)}
                    {isMobile && (
                    <div className="title">
                      <span className="lib">{t(msg75)}</span>
                      <span className="text-bold">x1</span>
                    </div>
                    )}
                    { !isMobile && (
                    <div className="input-betslip">
                      <div className="input-group d-flex align-items-center justify-content-center">
                        <span className="text-bold">1x</span>
                      </div>
                      <div className="input-group">
                        <button
                          className="btn"
                          type="button"
                          disabled={simpleTicket.stake <= minStake}
                          onClick={() => {
                            dispatch(stepStake({ increment: false, index: simpleTicket.betIndex }));
                            recompute();
                          }}
                        >
                          <img src={dash} alt="" />
                        </button>
                        <div className="form-label-stake">
                          <input
                            id={`stake-${index}`}
                            type="text"
                            placeholder={minStake}
                            className="form-control"
                            onFocus={((e) => e.target.select())}
                            name="stake"
                            onKeyDown={(e) => !(/^\d+$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') && e.preventDefault()}
                            disabled={bets.length < 1}
                            value={simpleTicket.stake}
                            onChange={(e) => handleSubInputPrice(e, simpleTicket.betIndex)}
                          />
                        </div>
                        <button
                          className="btn"
                          type="button"
                          disabled={simpleTicket.stake >= maxStake}
                          onClick={() => {
                            dispatch(stepStake({ increment: true, index: simpleTicket.betIndex }));
                            recompute();
                          }}
                        >
                          <img src={plus} alt="" />
                        </button>
                      </div>
                    </div>
                    )}
                    {isMobile && (
                    <div className="input-group">
                      <button
                        className="btn"
                        type="button"
                        disabled={simpleTicket.stake <= minStake}
                        onClick={() => { dispatch(stepStake({ increment: false, index: simpleTicket.betIndex })); recompute(); }}
                      >
                        <span className="icon" style={{ maskImage: `url(${dash})` }} />
                      </button>
                      <input
                        id="stake"
                        type="text"
                        placeholder={bets.length > 0 ? minStake : 0}
                        className="form-control"
                        onFocus={((e) => e.target.select())}
                        name="stake"
                        onKeyDown={(e) => !(/^\d+$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') && e.preventDefault()}
                        ref={inputRef}
                        disabled={bets.length < 1}
                        value={simpleTicket.stake}
                        onChange={handleInputPrice}
                      />
                      <button
                        className="btn"
                        type="button"
                        disabled={simpleTicket.stake >= maxStake}
                        onClick={() => { dispatch(stepStake({ increment: true, index: simpleTicket.betIndex })); recompute(); }}
                      >
                        <span className="icon" style={{ maskImage: `url(${plus})` }} />
                      </button>
                    </div>
                    )}
                  </div>
                  <div className="line">
                    <span className="lib">{t(msg76)}</span>
                    <span className="val">
                      {inValidSubStake(simpleTicket.stake) ? '-' : formatCurrency(simpleTicket.potentialWinnings, currencyConfigs)}
                    </span>
                  </div>
                  {simpleTicket.bonus > 0 && (
                  <div className="line">
                    <span className="lib">
                      Bonus(
                      {`${simpleTicket.bonusPercent}%`}
                      )
                    </span>
                    <span className="val">
                      {inValidSubStake(simpleTicket.stake) ? '-' : formatCurrency(simpleTicket.bonus, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {simpleTicket.bonus > 0 && (
                  <div className="line">
                    <span className="lib">
                      {`${t(msg158)} + Bonus(${bonusPercent}%)`}
                    </span>
                    <span className="val">
                      {inValidSubStake(simpleTicket.stake) ? '-' : formatCurrency(simpleTicket.potentialWinningsWithBonus, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {simpleTicket.TVA > 0 && (
                  <div className="line">
                    <span className="lib">{`${simpleTicket.tvaConfig.value}% ${simpleTicket.tvaConfig.type}`}</span>
                    <span className="val">
                      {inValidSubStake(simpleTicket.stake) ? '-' : formatCurrency(simpleTicket.TVA, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  <div className="line">
                    <span className="lib">{t(msg149)}</span>
                    <span className="val">
                      {inValidSubStake(simpleTicket.stake) ? '-' : formatCurrency(simpleTicket.finalWinnings, currencyConfigs)}
                    </span>
                  </div>
                </div>
              </li>
            );
          })
        )}
      </ul>
    );
  };
  return (
    <>
      <div className={isMobile ? `betslip ${toggleBetSlip ? 'mobile show' : 'mobile'}` : 'betslip'}>
        <form
          className="betslip-form-main"
          onSubmit={(event) => {
            event.preventDefault();
            sendResumeBet();
          }}
        >
          <div className="content">
            <BetSlipHeader
              closeMobileBetSlip={closeBetSlip}
              isMobile={isMobile}
              switchBetType={canSwitchToMultiple()}
              clearBetSlip={() => { dispatch(clearBetSlip()); }}
              betCount={(simple || multiple || multipleSimple) ? bets.length : numIntegralTickets}
              switchTo={{ multiple: (canSwitchToMultiple() && multipleSimple && validMultipleSimple()), simple: (multiple && validMultipleSimple()) }}
            />
            <div className="slip-body">
              <div className={`ctn-events${integral ? ' integral' : ''}${multipleSimple ? ' multiple-simple' : ''}`}>
                {slipBody()}
              </div>
              <div className="zone-bet">
                {bets.length > 0 && !isMobile && (<RetailBonusBetSection isMobile={isMobile} setAgentBonus={setAgentBonus} setRetailBetInfo={setRetailBetInfo} />)}
                <div className={isMobile ? `bet-details ${toggleBetDetails ? 'up' : ''}` : 'bet-details'}>
                  {bets.length > 0 && isMobile && (<RetailBonusBetSection isMobile={isMobile} setAgentBonus={setAgentBonus} setRetailBetInfo={setRetailBetInfo} />)}
                  {(simple || multiple)
                    && (
                    <div className="line">
                      <span className="lib">{t(msg157)}</span>
                      <span className="val">{odds}</span>
                    </div>
                    )}
                  {(simple || multiple || integral) && !isMobile && (
                  <div className="line">
                    <span className="lib">{t(msg75)}</span>
                    <div className="input-betslip">
                      {(simple || multiple)
                      && (
                      <div className="input-group d-flex align-items-center justify-content-center">
                        <span className="text-bold">1x</span>
                      </div>
                      )}
                      {(integral)
                      && (
                      <div className="input-group d-flex align-items-center justify-content-center">
                        <span className="text-bold">
                          {numIntegralTickets}
                          x
                        </span>
                      </div>
                      )}
                      <div className="input-group">
                        <button
                          className="btn"
                          type="button"
                          disabled={bets.length < 1 || stake <= minStake}
                          onClick={() => dispatch(stepStake({ increment: false }))}
                        >
                          <img src={dash} alt="" />
                        </button>
                        <div className="form-label-stake">
                          <input
                            id="stake"
                            type="text"
                            placeholder={bets.length > 0 ? minStake : 0}
                            className="form-control"
                            onFocus={((e) => e.target.select())}
                            name="stake"
                            onKeyDown={(e) => !(/^\d+$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') && e.preventDefault()}
                            ref={inputRef}
                            disabled={bets.length < 1}
                            value={bets.length > 0 ? stake : 0}
                            onChange={handleInputPrice}
                          />
                        </div>
                        <button
                          className="btn"
                          type="button"
                          disabled={bets.length < 1 || stake >= maxStake}
                          onClick={() => dispatch(stepStake({ increment: true }))}
                        >
                          <img src={plus} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                  )}
                  {(exciseTax > 0) && (
                  <div className="line">
                    <span className="lib">{`${exciseTaxConfig.value}% ${exciseTaxConfig.type}`}</span>
                    <span className="val">
                      {inValidBetSlip() ? '-' : formatCurrency(exciseTax, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {(stakeWithoutTax > 0 && exciseTax > 0) && (
                  <div className="line">
                    <span className="lib">{t(msg145)}</span>
                    <span className="val">
                      {inValidBetSlip() ? '-' : formatCurrency(stakeWithoutTax, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {((simple || multiple)) && (
                  <div className="line">
                    <span className="lib">{t(msg76)}</span>
                    <span className="val">
                      {inValidBetSlip() ? '-' : formatCurrency(potentialWinnings, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {((simple || multiple) && bonus > 0) && (
                  <div className="line">
                    <span className="lib">
                      Bonus(
                      {`${bonusPercent}%`}
                      )
                    </span>
                    <span className="val">
                      {inValidBetSlip() ? '-' : formatCurrency(bonus, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {((simple || multiple) && bonus > 0) && (
                  <div className="line">
                    <span className="lib">
                      {`${t(msg158)} + Bonus(${bonusPercent}%)`}
                    </span>
                    <span className="val">
                      {inValidBetSlip() ? '-' : formatCurrency(potentialWinningsWithBonus, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {((simple || multiple) && TVA > 0) && (
                  <div className="line">
                    <span className="lib">{`${tvaConfig.value}% ${tvaConfig.type}`}</span>
                    <span className="val">
                      {inValidBetSlip() ? '-' : formatCurrency(TVA, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {((simple || multiple)) && (
                  <div className="line">
                    <span className="lib">{t(msg149)}</span>
                    <span className="val">
                      {inValidBetSlip() ? '-' : formatCurrency(finalWinnings, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {integral && (
                  <div className="line">
                    <span className="lib">{t(msg162)}</span>
                    <span className="val">
                      {inValidBetSlip() ? '-' : formatCurrency(integralTickets.reduce((acc, ticket) => acc + ticket.potentialWinningsWithBonus, 0), currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {(simple || multiple) && (
                  <div className="amt-final">
                    <span>{t(msg77)}</span>
                    <p>
                      {inValidBetSlip() ? '-' : formatCurrency(stake, currencyConfigs)}
                    </p>
                  </div>
                  )}
                  {(integral) && (
                  <div className="amt-final">
                    <span>{t(msg77)}</span>
                    <p>
                      {inValidBetSlip() ? '-' : formatCurrency(stake * numIntegralTickets, currencyConfigs)}
                    </p>
                  </div>
                  )}
                  {(multipleSimple) && (
                  <div className="amt-final">
                    <span>{t(msg77)}</span>
                    <p>
                      {inValidBetSlip() ? '-' : formatCurrency(simpleTickets.reduce((acc, ticket) => acc + ticket.stake, 0), currencyConfigs)}
                    </p>
                  </div>
                  )}
                  {(alerts.length > 0 && !isMobile) && <ShowAlert />}
                </div>
                <div className="betslip-footer">
                  {isMobile && bets.length > 0 && (
                  <div className="betslip-menu">
                    {(simple || multiple || integral) && (
                    <div className="stake">
                      <div className="title">
                        <span className="lib">{t(msg75)}</span>
                        <span className="text-bold">{(simple || multiple) ? 'x1' : `${numIntegralTickets}x`}</span>
                      </div>
                      <div className="input-group">
                        <button
                          className="btn"
                          type="button"
                          disabled={bets.length < 1 || stake <= minStake}
                          onClick={() => dispatch(stepStake({ increment: false }))}
                        >
                          <span className="icon" style={{ maskImage: `url(${dash})` }} />
                        </button>
                        <input
                          id="stake"
                          type="text"
                          placeholder={bets.length > 0 ? minStake : 0}
                          className="form-control"
                          onFocus={((e) => e.target.select())}
                          name="stake"
                          onKeyDown={(e) => !(/^\d+$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') && e.preventDefault()}
                          ref={inputRef}
                          disabled={bets.length < 1}
                          value={bets.length > 0 ? stake : 0}
                          onChange={handleInputPrice}
                        />
                        <button
                          className="btn"
                          type="button"
                          disabled={bets.length < 1 || stake >= maxStake}
                          onClick={() => dispatch(stepStake({ increment: true }))}
                        >
                          <span className="icon" style={{ maskImage: `url(${plus})` }} />
                        </button>
                      </div>
                    </div>
                    )}
                    <button className="btn btn-bet-details" type="button" onClick={switchBetDetails}>
                      <span>{msg181}</span>
                      <span className={`icon${toggleBetDetails ? ' rot-180' : ''}`} style={{ maskImage: `url(${downIcon})` }} />
                    </button>
                  </div>
                  )}
                  {(alerts.length > 0 && isMobile) && <ShowAlert />}
                  <div className="actions">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ color }}
                      disabled={inValidBetSlip()}
                    >
                      {resumeBetLoading && (
                      <span className="spinner-border spinner-border-sm" />
                      )}
                      {t(msg79)}
                      {isMobile && (simple || multiple) && !inValidBetSlip() && ` (${formatCurrency(finalWinnings, currencyConfigs)})`}
                      {isMobile && integral && !inValidBetSlip() && ` (${formatCurrency(integralTickets.reduce((acc, ticket) => acc + ticket.potentialWinningsWithBonus, 0), currencyConfigs)})`}
                      {isMobile && multipleSimple && !inValidBetSlip() && ` (${formatCurrency(simpleTickets.reduce((acc, ticket) => acc + ticket.finalWinnings, 0), currencyConfigs)})`}
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
      {(showRechargeConfirm || errorGetBalance || canPrint || loadingHistory || histMsg || resumeBetLoading || msg
        || isbalanceLoading || checkWithdrawLoading || isErrorVerifyTckt || isTicketLoading || isCancelTicketLoading
        || isPlaceBetLoading || isPaidLoading || isdepositLoading || isBonRechargeLoading || withdrawWithCodeLoading
        || verifyTicketMsg || errorBonRecharge || rechargeSuccess || failedCheckWithdraw || canWithdraw || canNotWithdraw
        || bonRechargeSuccess || successGetBalance || showBonRechargeConfirm || failedPayTicket || showPayConfirm
        || showCancelConfirm || ticketPaid || success || showPreview || successfetchTicket || ticketCanceled || failedCancelTicket
        || accountCreation.message || accountValidation.message || resendActivationCode.message || ticketNotRegistered
        || ticketAlreadyCanceled || resetLoading || resetMessage || resetSuccessMsg || printError) && <Modal isMobile={isMobile} />}
      {resetPinForm && !isMobile && <ResetPinComponent containerClass="modalContainer" />}
    </>
  );
};

BetSlip.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  toggleBetSlip: PropTypes.bool.isRequired,
  closeBetSlip: PropTypes.func.isRequired,
};

export default BetSlip;
