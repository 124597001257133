/* eslint  max-len: 0 */
import React, { useEffect, useState } from 'react';
import {
  Route, Routes, Navigate, useLocation,
} from 'react-router-dom';
// import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getBetConfigs, getEventRestrictions } from 'redux/configs/betConfigsSlice';
import { logout } from 'redux/login/loginSlice';
import { homeListData } from 'redux/homeDashboard/homeDashBoardSlice';
import { getCurrencyConfigs } from 'redux/configs/currencyConfigsSlice';
import { getSystemConfigs } from 'redux/configs/systemConfigsSlice';
import PageNotFound from 'components/pageNotFound/PageNotFound';
// import { getSystemConfigs } from 'redux/configs/systemConfigsSlice';
import { getRefresToken } from 'redux/login/refreshTokenSlice';
import defaultSportId from 'components/helpers/defaultSport';
import './App.scss';
import Login from 'components/sessions/Login';
import Dashboard from 'components/dashboard/Dashboard';
import SinglePage from 'components/dashboard/SinglePage';
import Home from 'components/dashboard/content/Home';
// import JackPot from 'components/dashboard/content/JackPot';
import MeilleursParis from 'components/dashboard/content/top-matches/MeilleursParis';
import VerifyTicket from 'components/dashboard/content/home-details/VerifyTicket';
import History from 'components/dashboard/content/home-details/History';
import UniversalComponent from 'components/dashboard/content/UniversalComponent';
import TodayMatches from 'components/dashboard/content/today-matches/TodayMatches';
import MatchDetails from 'components/dashboard/content/match-details/MatchDetails';
// import Search from 'components/dashboard/content/home-details/Search';
import Deposit from 'components/dashboard/content/home-details/Deposit';
import Withdrawal from 'components/dashboard/content/home-details/Withdrawal';
import Solde from 'components/dashboard/content/home-details/Solde';
import AccountManagement from 'components/dashboard/content/home-details/AccountManagement';

const App = () => {
  const [isActive, setIsActive] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const {
    isLoggedIn,
  } = useSelector((store) => store.login);

  const { details, tournament, dashboard } = useSelector((store) => store.navigationHeader);

  useEffect(() => {
    const handleUserActivity = () => {
      setIsActive(true);
    };

    const handleInactive = () => {
      setIsActive(false);
    };

    document.addEventListener('mousemove', () => {
      handleUserActivity();
    });
    document.addEventListener('keydown', () => {
      handleUserActivity();
    });
    document.addEventListener('touchstart', () => {
      handleUserActivity();
    });
    document.addEventListener('click', () => {
      handleUserActivity();
    });
    setIsActive(true);
    const timeout = setTimeout(() => {
      handleInactive();
    }, 30 * 60 * 1000); // User is considered inactive after 30 minutes of inactivity

    // Cleanup function to remove event listeners and clear timeout
    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      document.removeEventListener('touchstart', handleUserActivity);
      document.removeEventListener('click', handleUserActivity);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem('token');

    if (!token && isLoggedIn) {
      dispatch(logout());
      window.location.reload();
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    const expireAt = sessionStorage.getItem('expireAt');
    if (expireAt && (expireAt < Date.now())) {
      if (isActive) {
        dispatch(getRefresToken());
        console.log('Token expired and user is active. Refreshing token...');
      } else {
        console.log('Token expired and user is inactive. Logging out...');
        dispatch(logout());
        window.location.reload();
      }
    }
  }, [isActive, dispatch]);

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      const userLanguage = navigator.language.split('-')[0]; // Extract the language code e.g en-US -> en
      localStorage.setItem('lang', userLanguage); // Make language available in local storage for api calls
    }

    if (!localStorage.getItem('sportSelected')) {
      localStorage.setItem('sportSelected', JSON.stringify(defaultSportId)); // Set default sport to football when you click on Aujourd'hui or Today
    }
  }, [pathname]);
  useEffect(() => {
    dispatch(getBetConfigs()); // Load configs when app loads. These configs are found in betConfigsSlice at getBetConfigs.fulfilled
    dispatch(getEventRestrictions()); // Load event restrictions when app loads. These configs are found in betConfigSlice at getEventRestrictions.fulfilled
    dispatch(homeListData());
    dispatch(getCurrencyConfigs()); // Load currencyconfigs used on bet slips calculations
    dispatch(getSystemConfigs());
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    localStorage.setItem('headerTitle', JSON.stringify({ details, tournament, dashboard }));
  }, [details, tournament, dashboard]);

  return (
    <Routes>
      <Route
        index
        element={<Navigate to="dashboard/home" />}
      />
      <Route path="login" element={<Login isMobile={isMobile} />} />
      <Route path="dashboard" element={<Dashboard />}>
        <Route path="" element={<SinglePage />}>
          <Route index element={<Navigate to="home" />} />
          <Route path="home" element={<Home isMobile={isMobile} />} />
          {/* <Route path="jackpot" element={<JackPot />} /> */}
          {/* <Route path="search" element={<Search />}/> */}
          <Route path="meilleurs-paris" element={<MeilleursParis isMobile={isMobile} />} />
          <Route path="verifier-ticket" element={<VerifyTicket isMobile={isMobile} />} />
          <Route path="history" element={<History isMobile={isMobile} />} />
          <Route path="matches" element={<UniversalComponent isMobile={isMobile} />} />
          <Route path="today-matches" element={<TodayMatches isMobile={isMobile} />} />
          <Route path="meilleures-competitions" element={<Home isMobile={isMobile} />} />
          <Route path="match-details" element={<MatchDetails isMobile={isMobile} />} />
          <Route path="deposit" element={<Deposit />} />
          <Route path="withdrawal" element={<Withdrawal />} />
          <Route path="solde" element={<Solde />} />
          <Route path="account-creation" element={<AccountManagement />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
