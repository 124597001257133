import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  authParams, getBase64Hash, token,
} from 'components/helpers/base64AuthParams';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29, msg92 } = translationKeys;
export const getHistory = createAsyncThunk(
  'History/getHistory',
  async (obj, thunkAPI) => {
    const api = `/agent-tansactions/${obj.agentId}?startDate=${obj.startDate}&endDate=${obj.endDate}&type=${obj.type}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/agent-tansactions/${obj.agentId}startDate=${obj.startDate}&endDate=${obj.endDate}&type=${obj.type}`;
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.get(url, { headers });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  history: [],
  loadingHistory: false,
  errorHistory: '',
  histMsg: '',
  historyLoaded: false,
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    hideHistModal: (state) => ({
      ...state,
      histMsg: '',
    }),
    resetHistoryLoaded: (state) => ({
      ...state,
      historyLoaded: false,
      history: [],
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(getHistory.pending, (state) => ({
        ...state,
        loadingHistory: true,
      }))
      .addCase(getHistory.fulfilled, (state, { payload }) => {
        let histMsg = '';
        let history = [];
        let historyLoaded = false;
        if (payload.detailMessage.erc === '1' && payload.data !== null) {
          historyLoaded = true;
          history = payload.data;
          histMsg = '';
        }
        if (payload.detailMessage.erc === '1' && payload.data === null) {
          histMsg = msg92;
        }
        return {
          ...state,
          history,
          histMsg,
          historyLoaded,
          loadingHistory: false,
        };
      })

      .addCase(getHistory.rejected, (state, { payload }) => {
        let histMsg;
        if (payload && payload.erc && payload.erc === '001') {
          histMsg = tokenText;
        } else { histMsg = msg29; }
        return {
          ...state,
          loadingHistory: false,
          errorHistory: msg29,
          histMsg,
        };
      });
  },
});

export const {
  hideHistModal, resetHistoryLoaded,
} = historySlice.actions;

export default historySlice.reducer;
