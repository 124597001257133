/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash, token } from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29 } = translationKeys;
export const getClientBonus = createAsyncThunk(
  'client/bonus',
  async (agentId, thunkAPI) => {
    const lang = getLanguage();
    const requestParams = `lang=${lang}&id=${agentId}&channel_id=${userObj.chl}`;
    const api = `/user/bonus-balance?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/user/bonus-balance${requestParams}`;
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        Authorization: token ? `Bearer ${token}` : '',
      };
      const resp = await client.get(url, { headers });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error?.response?.data?.message || 'An error occurred',
        erc: error?.response?.data?.erc || 0,
      });
    }
  },
);

const initialState = {
  bonuses: [],
  bonusLoading: false,
  bonusError: '',
  bonusSuccess: false,
};

const clientBonusSlice = createSlice({
  name: 'agent-bonus',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getClientBonus.pending, (state) => ({
        ...state,
        bonusLoading: true,
      }))
      .addCase(getClientBonus.fulfilled, (state, { payload }) => ({
        ...state,
        bonusLoading: false,
        bonuses: payload,
        bonusSuccess: true,
      }))

      .addCase(getClientBonus.rejected, (state, { payload }) => {
        let bonusError;
        if (payload && payload.erc && payload.erc === '001') {
          bonusError = tokenText;
        } else { bonusError = msg29; }
        return {
          ...state,
          bonusLoading: false,
          bonusSuccess: false,
          bonusError,
          bonuses: [],
        };
      });
  },
});

export default clientBonusSlice.reducer;
