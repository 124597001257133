/* eslint  max-len: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import translationKeys from 'translations/translationKeys';
import tokenText from 'components/helpers/tokenText';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});
const { msg29, msg37 } = translationKeys;
export const payTicket = createAsyncThunk(
  'Tickets/payTicket',
  async (obj, thunkAPI) => {
    const requestParams = `refCode=${obj.refCode}&type=${obj.type}&userId=${obj.agentId}&pwd=${obj.agentPwd}&imei=${obj.imei}`;
    const api = `/ser_confirm_disbursed?${requestParams}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/ser_confirm_disbursed${requestParams}`;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
        },
      };

      const resp = await client.get(url, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message || 'An error occurred',
        erc: error.response.data.erc,
      });
    }
  },
);

const initialState = {
  ticketPaid: false,
  isPaidLoading: false,
  successPayTicket: false,
  failedPayTicket: false,
  payTicketMsg: '',
  paidTicketPrintData: [],
  errorPayTicket: '',
  showPayConfirm: false,
};

const payTicketSlice = createSlice({
  name: 'pay-ticket',
  initialState,
  reducers: {
    resetPayTicket: (state) => ({
      ...state,
      ticketPaid: false,
      failedPayTicket: false,
    }),

    showPayConfirmBox: (state) => ({
      ...state,
      showPayConfirm: true,
    }),
    hidePayConfirmBox: (state) => ({
      ...state,
      showPayConfirm: false,
    }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(payTicket.pending, (state) => ({
        ...state,
        isPaidLoading: true,
        successCancelTicket: false,
      }))
      .addCase(payTicket.fulfilled, (state, { payload }) => {
        let ticketPaid = false;
        let successPayTicket = false;
        let failedPayTicket = false;
        let paidTicketPrintData = [];
        let payTicketMsg = '';
        const showPayConfirm = false;
        if (payload.erc === '1') {
          ticketPaid = true;
          successPayTicket = true;
          payTicketMsg = msg37;
          paidTicketPrintData = JSON.parse(payload.msg).new.data;
        } if (payload.erc === '0') {
          failedPayTicket = true;
          payTicketMsg = msg29;
        }
        return {
          ...state,
          paidTicketPrintData,
          ticketPaid,
          isPaidLoading: false,
          successPayTicket,
          showPayConfirm,
          failedPayTicket,
          payTicketMsg,
          errorPayTicket: '',
        };
      })

      .addCase(payTicket.rejected, (state, { payload }) => {
        let failedPayTicket = false;
        let payTicketMsg;
        if (payload && payload.erc && payload.erc === '001') {
          failedPayTicket = true;
          payTicketMsg = tokenText;
        } else { payTicketMsg = msg29; }
        return {
          ...state,
          isPaidLoading: false,
          successPayTicket: false,
          failedPayTicket,
          payTicketMsg,
          error: msg29,
        };
      });
  },
});

export const {
  resetPayTicket, showPayConfirmBox, hidePayConfirmBox,
} = payTicketSlice.actions;

export default payTicketSlice.reducer;
